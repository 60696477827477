import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, skipWhile } from 'rxjs/operators';

import { userHasPermission } from '@coyards/shared/utilities';
import { MainFacade } from '../modules/main/store/main.facade';

@Injectable()
export class PermissionGuard implements CanActivate, CanActivateChild {
  constructor(private mainFacade: MainFacade, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkPermission(
      route.data['permissionName'] || '',
      route.data['redirectTo']
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkPermission(childRoute.data['permissionName'] || '');
  }

  private checkPermission(name: string, redirectTo?: string) {
    return this.mainFacade.loggedUser$.pipe(
      skipWhile((user) => user === null),
      map((user) => {
        const hasPermission = userHasPermission(name, user?.permissions);
        if (!hasPermission) {
          if (redirectTo) {
            this.router.navigate([redirectTo]);
          } else {
            this.router.navigate(['/']);
          }
        }
        return hasPermission;
      }),
      catchError((e) => {
        console.log('errror', e);
        return of(false);
      })
    );
  }
}
