<div class="stepper">
  <ui-preset-title
    [title]="(stepperConfig.title | translation : stepperConfig.translateContext) + ' ' + (selectedIndex + 1)"
    [titleClassList]="titleClassList"
    [subtitle]="selected.label | translation : stepperConfig.translateContext"
    [subtitleClassList]="subtitleClassList"
  >
  </ui-preset-title>

  <div class="stepper__content" *ngIf="selected">
    <div [classList]="staticContentClassList">
      <ng-content select="[constantContent]"></ng-content>
    </div>
    <ng-container [ngTemplateOutlet]="selected.content"></ng-container>
  </div>

  <div [class]="selectedIndex ? 'flex j-space-between' : 'flex j-end'">
    <ui-button
      *ngIf="selectedIndex"
      [classList]="['btn', 'btn-center', 'btn-l']"
      [btnType]="'flat'"
      [btnColor]="'secondary'"
      [btnText]="stepperConfig.backBtnLabel | translation : stepperConfig.translateContext"
      (clickEvent)="onChangeStep('previous')"
    >
    </ui-button>
    <ui-button
      [classList]="['btn', 'btn-center', 'btn-l']"
      [btnType]="'flat'"
      [btnColor]="'accent'"
      [btnText]="selectedIndex + 1 === steps.length
      ? (stepperConfig.lastBtnLabel | translation : stepperConfig.translateContext)
      : stepperConfig.nextBtnLabel | translation : stepperConfig.translateContext"
      [disable]="!selected.completed"
      (clickEvent)="onChangeStep('next')"
    >
    </ui-button>
  </div>
</div>
