<a class="icon" (click)="onClick($event)">
  <img
    width="24px"
    height="24px"
    class="icon__content"
    [ngClass]="classList"
    [defaultImage]="ICON_SHARED_URL + 'default.svg'"
    [lazyLoad]="ICON_SHARED_URL + iconName"
    [matTooltip]="iconTooltipText"
    [matTooltipPosition]="iconTooltipPosition"
    [matTooltipClass]="tooltipClassList"
    alt="Tooltip icon"
  />
</a>
