<span
  class="field"
  [ngClass]="[iconName ? 'field--with-icon' : '']"
  [style.color]="customColor"
  [style.background-color]="customBgColor"
  [class.disable]="disable"
>
  <label
    class="field__label"
    [ngClass]="[
      value === null || value === undefined || value === '' ? '' : 'floated'
    ]"
  >
    <ui-text
      [classList]="['placeholder-m', 'placeholder-height-s']"
      [text]="labelText"
    >
    </ui-text>
  </label>

  <span *ngIf="iconName" class="field__icon field__icon--left">
    <img
      width="20px"
      height="20px"
      [defaultImage]="ICON_SHARED_URL + 'default.svg'"
      [lazyLoad]="ICON_BASE_URL + iconName + '.svg'"
      alt="Input icon"
    />
  </span>

  <input
    matInput
    type="text"
    class="field__input"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="off"
    [maxlength]="maxlength"
    [ngClass]="classList"
    [formControl]="textFormControl"
    [placeholder]="placeholder"
    [value]="value"
    (keyup)="keyUp($event)"
  />

  <mat-error *ngIf="hasError && errorText" class="field__error">
    <ui-text
      [classList]="['placeholder-m', 'placeholder-height-s']"
      [text]="errorText"
    >
    </ui-text>
  </mat-error>
</span>
