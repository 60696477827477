<div *ngIf="data" class="card">
  <div class="card__box flex fd-column">
    <div class="flex j-space-between a-center">
      <ui-preset-title
        class="card__name"
        [matTooltip]="data?.name?.length > 13 ? data?.name : ''"
        [class.card__pointer]="canEdit || data?.name?.length > 13"
        [title]="data?.name | truncate : [13]"
        [titleClassList]="nameClassList"
        (click)="onEdit()"
      >
      </ui-preset-title>
      <ui-preset-title
        [subtitle]="
          data?.created
            ? ('table_registered_label' | translation : 'user_page') +
              ' ' +
              (data?.created | date)
            : ''
        "
        [subtitleClassList]="registrationDateClassList"
      >
      </ui-preset-title>
    </div>

    <div class="card__body flex j-space-between a-end">
      <div class="card__contacts">
        <img
          width="20px"
          height="20px"
          [defaultImage]="ICON_SHARED_URL + 'default.svg'"
          [lazyLoad]="ICON_BASE_URL + 'phone.svg'"
          alt="phone icon"
        />

        <ui-preset-title
          class="flex a-center"
          [subtitle]="data?.phoneNumber"
          [subtitleClassList]="contactTextClassList"
        >
        </ui-preset-title>

        <img
          width="20px"
          height="20px"
          [defaultImage]="ICON_SHARED_URL + 'default.svg'"
          [lazyLoad]="ICON_BASE_URL + 'email.svg'"
          alt="email icon"
        />

        <ui-preset-title
          class="flex a-center"
          [subtitle]="data?.email"
          [subtitleClassList]="contactTextClassList"
        >
        </ui-preset-title>
      </div>

      <button *ngIf="canEdit" (click)="onEdit()" class="edit-btn">
        <img
          width="20px"
          height="20px"
          [defaultImage]="ICON_SHARED_URL + 'default.svg'"
          [lazyLoad]="ICON_BASE_URL + 'edit.svg'"
          alt="edit icon"
        />
      </button>
    </div>
  </div>
</div>
