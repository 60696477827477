export interface IStepperConfig {
  title: string;
  backBtnLabel: string;
  nextBtnLabel: string;
  lastBtnLabel: string;
  translateContext: string;
}

export interface IStepChange {
  stepIndex: number;
  isNext: boolean;
}
