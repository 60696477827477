import { IMenuItem, IPermission, IRole } from '@coyards/shared/models';

export function userHasRole(
  roleName: string,
  roles: string[] | IRole[] = []
): boolean {
  return roles.some((role) =>
    roles[0] && typeof roles[0] !== 'string'
      ? role.name === roleName
      : role === roleName
  );
}

export function userHasPermission(
  name: string,
  permissions: IPermission[] = []
): boolean {
  return permissions.some((permission) => permission.name === name);
}

export function userHasMenuItem(
  name: string,
  menuItems: IMenuItem[] = []
): boolean {
  return menuItems.some((menuItem) => menuItem.technicalName === name);
}
