export interface ITableColumnConfig {
  columnDef: string;
  type: TableCellType;
  headerTextId: string;
  headerToolTipTextId: string;
  headerClass: string[];
  showHeaderSort: boolean;
  showHead: boolean;
  cell: (data: unknown, compRef?: unknown) => string;
  translationContext: string;
  showCell: boolean;
  sortOrder: number;
  disable: boolean;
  cellClass: string[];
  show: () => boolean;
  skipTooltipTranslate?: boolean;
  skipIconTooltipTranslate?: boolean;
  cellIcon?: (data: unknown, compRef?: unknown) => string;
  // applicable only for IMAGE_AND_TEXT type
  imageClass?: string[];
  iconBeforeText?: (data: unknown, compRef?: unknown) => string;
  iconBeforeTextToolTip?: (data: unknown, compRef?: unknown) => string;

  dataClass?: (data: unknown, compRef?: unknown) => string[];
  sortValue?: (data: unknown) => string | number | boolean | Date;
  cellToolTip?: (data: unknown, compRef?: unknown) => string;
  cellIconToolTip?: (data: unknown, compRef?: unknown) => string;
}

export interface ITableCellConfig {
  type: TableCellType;
  dataClass?: string[];
  text?: string;
  textTooltip?: string;
  icon?: string;
  imageClass?: string[];
  iconTooltip?: string;
  highlight?: IHighlight;
  iconBeforeText?: string;
  iconBeforeTextToolTip?: string;
}

export enum TableCellType {
  TEXT,
  IMAGE_AND_TEXT,
  NONE,
  HIGHLIGHT,
}

export interface IHighlight {
  nonHighlightedItems: string[];
  highlightedItems: string[];
}

export enum TableActionsEnum {
  DISABLE,
  TOOLTIP_TEXT,
}

export enum SelectionEnum {
  HAS_VALUE,
  IS_SELECTED,
}
