import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ITablink } from '@coyards/shared/models';
import { TranslationPipe } from '@coyards/shared/pipes';
import { UiTextComponent } from '../ui-text/ui-text.component';

@Component({
  selector: 'ui-navbar-tablink',
  standalone: true,
  imports: [
    CommonModule,
    MatTooltipModule,
    RouterModule,
    MatTabsModule,
    UiTextComponent,
    TranslationPipe,
  ],
  templateUrl: './ui-navbar-tablink.component.html',
  styleUrls: ['./ui-navbar-tablink.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiNavbarTablinkComponent {
  @Input() tabLinks: ITablink[] = [];
  @Input() tabsAlign = 'center';
  @Input() disable: boolean;
  @Input() translateContext: string;

  @Output() clickEvent = new EventEmitter();

  onClickEvent(link: ITablink) {
    this.clickEvent.emit(link);
  }
}
