import { ActionColors } from './cards';

export interface IDropOption {
  id?: number;
  sortOrder?: number;
  name?: string;
  text?: string;
  textId?: string;
  icon?: string;
  route?: string;
  disable?: any;
  hide?: any;
  showTooltip?: any;
  color?: ActionColors;
}
