import { Component, OnInit } from '@angular/core';
import { IToast } from '@coyards/shared/models';
import { Observable, tap } from 'rxjs';
import { MainFacade } from './modules/main/store';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'coyards-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  toast$: Observable<IToast>;
  showToast = false;

  constructor(
    private authService: AuthService,
    private mainFacade: MainFacade
  ) {}

  ngOnInit(): void {
    this.authService.updateCurrentUserFromServer();
    this.toast$ = this.mainFacade.toast$.pipe(
      tap((toast) => {
        if (toast) {
          this.showToast = true;
        }
      })
    );
  }
}
