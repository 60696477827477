export interface IActivities {
  icon: string;
  score: number;
  name: string;
  title: string;
}

export interface IStatistics {
  dau: number;
  mau: number;
  totalRegisteredUsers: number;
  totalBroadcastAdmins: number;
  totalGroups: number;
  averageUsersPerGroup: number;
  averageAlarmsSentPerGroup: number;
  averageMessagesSentPerGroup: number;
  totalCountries: number;
  countries: ICountry[];
}

export interface ICountry {
  countryName: string;
  users: number;
}
