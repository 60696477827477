<div class="dropdown">
  <ng-container [ngSwitch]="btnType">
    <ng-container *ngSwitchCase="BUTTON_TYPE.RAISED">
      <button
        mat-raised-button
        class="btn"
        [ngClass]="classList"
        [color]="btnColor"
        [matMenuTriggerFor]="menu"
        [style.background-color]="btnCustomColor"
        [matTooltip]="iconTooltipText"
        [matTooltipPosition]="iconTooltipPosition"
        [disabled]="disable"
        aria-label="btnAriaLabel"
        (menuOpened)="isMenuOpened = true"
        (menuClosed)="isMenuOpened = false"
      >
        <ng-container [ngTemplateOutlet]="btnContent"></ng-container>
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="BUTTON_TYPE.FLAT">
      <button
        mat-flat-button
        class="btn"
        [ngClass]="classList"
        [color]="btnColor"
        [matMenuTriggerFor]="menu"
        [style.background-color]="btnCustomColor"
        [matTooltip]="iconTooltipText"
        [matTooltipPosition]="iconTooltipPosition"
        [disabled]="disable"
        aria-label="btnAriaLabel"
        (menuOpened)="isMenuOpened = true"
        (menuClosed)="isMenuOpened = false"
      >
        <ng-container [ngTemplateOutlet]="btnContent"></ng-container>
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="BUTTON_TYPE.STROKED">
      <button
        mat-stroked-button
        class="btn"
        [ngClass]="classList"
        [color]="btnColor"
        [matMenuTriggerFor]="menu"
        [style.background-color]="btnCustomColor"
        [matTooltip]="iconTooltipText"
        [matTooltipPosition]="iconTooltipPosition"
        [disabled]="disable"
        aria-label="btnAriaLabel"
        (menuOpened)="isMenuOpened = true"
        (menuClosed)="isMenuOpened = false"
      >
        <ng-container [ngTemplateOutlet]="btnContent"></ng-container>
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="BUTTON_TYPE.ICON">
      <button
        mat-icon-button
        class="btn"
        [ngClass]="classList"
        [color]="btnColor"
        [matMenuTriggerFor]="menu"
        [style.background-color]="btnCustomColor"
        [matTooltip]="iconTooltipText"
        [matTooltipPosition]="iconTooltipPosition"
        [disabled]="disable"
        aria-label="btnAriaLabel"
        (menuOpened)="isMenuOpened = true"
        (menuClosed)="isMenuOpened = false"
      >
        <ng-container [ngTemplateOutlet]="btnContent"></ng-container>
      </button>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <button
        mat-button
        class="btn"
        [ngClass]="classList"
        [color]="btnColor"
        [matMenuTriggerFor]="menu"
        [style.background-color]="btnCustomColor"
        [matTooltip]="iconTooltipText"
        [matTooltipPosition]="iconTooltipPosition"
        [disabled]="disable"
        aria-label="btnAriaLabel"
        (menuOpened)="isMenuOpened = true"
        (menuClosed)="isMenuOpened = false"
      >
        <ng-container [ngTemplateOutlet]="btnContent"></ng-container>
      </button>
    </ng-container>
  </ng-container>
</div>

<ng-template #btnContent>
  <span class="btn__content">
    <img
      *ngIf="btnImageURL"
      width="24px"
      height="24px"
      class="btn__img"
      [defaultImage]="ICON_SHARED_URL + 'default.svg'"
      [lazyLoad]="SERVER_URL + (btnImageURL | replace : '.' : '_small.')"
      alt="btn image"
    />
    <img
      *ngIf="!btnImageURL && btnImage"
      width="24px"
      height="24px"
      class="btn__img"
      [defaultImage]="ICON_SHARED_URL + 'default.svg'"
      [lazyLoad]="IMG_BASE_URL + btnImage"
      alt="btn image"
    />
    <img
      *ngIf="btnIcon"
      width="24px"
      height="24px"
      class="btn__icon"
      [defaultImage]="ICON_SHARED_URL + 'default.svg'"
      [lazyLoad]="ICON_BASE_URL + btnIcon + '.svg'"
      alt="btn icon"
    />
    <ui-text
      class="btn__text truncate"
      [classList]="['placeholder-m', 'placeholder-height-s']"
      [text]="btnText"
    >
    </ui-text>
  </span>
  <button
    *ngIf="!btnIcon"
    class="btn__arrow"
    [ngClass]="{ disable: disable, opened: isMenuOpened }"
  >
    <img
      width="24px"
      height="24px"
      [defaultImage]="ICON_SHARED_URL + 'default.svg'"
      [lazyLoad]="ICON_BASE_URL + 'drop-arrow.svg'"
      alt="arrow"
    />
  </button>
</ng-template>

<mat-menu #menu="matMenu" class="menu__panel">
  <ng-container *ngFor="let option of visibleOptions; let lastIndex = last">
    <div
      [matTooltip]="
        optionBindData && option?.showTooltip
          ? (option.showTooltip(optionBindData)
            | translation : translateContext)
          : ''
      "
    >
      <button
        class="menu__content"
        mat-menu-item
        *ngIf="
          optionBindData && option?.hide ? !option.hide(optionBindData) : true
        "
        [class.disable]="
          option?.disable(optionBindData) ||
          disableOptionIds?.includes(option?.id)
        "
        [class.danger]="option.color === actionColors.RED"
        [ngClass]="optionClassList"
        [disabled]="
          option?.disable(optionBindData) ||
          disableOptionIds?.includes(option?.id)
        "
        (click)="emitClick(option)"
      >
        <img
          *ngIf="option.icon"
          width="20px"
          height="20px"
          class="menu__icon"
          [class.danger]="option.color === actionColors.RED"
          [defaultImage]="ICON_SHARED_URL + 'default.svg'"
          [lazyLoad]="ICON_BASE_URL + option.icon + '.svg'"
          alt="menu icon"
        />
        <ui-text
          class="menu__text truncate"
          [classList]="['placeholder-m', 'placeholder-height-s']"
          [text]="
            option?.textId && translateContext
              ? (option?.textId | translation : translateContext)
              : option?.text
          "
        >
        </ui-text>
      </button>
    </div>

    <ui-divider *ngIf="!lastIndex" [classList]="['divider-nogap']">
    </ui-divider>
  </ng-container>
  <div *ngIf="showAdminPanelVersion" class="admin-panel-version">
    <ui-text
      [text]="'header_admin_panel_version' | translation : 'header'"
    ></ui-text>
    <ui-text [text]="'3.0'"></ui-text>
  </div>
</mat-menu>
