<ng-container [ngSwitch]="btnType">
  <ng-container *ngSwitchCase="BUTTON_TYPE.RAISED">
    <button
      type="button"
      mat-raised-button
      class="btn"
      aria-label="btnAriaLabel"
      [type]="roleType"
      [ngClass]="classList"
      [color]="btnColor"
      [style.background-color]="btnCustomColor"
      [style.color]="btnTextColor"
      [disabled]="disable"
      (click)="onClickButton($event)"
    >
      <ng-container [ngTemplateOutlet]="btnContent"></ng-container>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="BUTTON_TYPE.FLAT">
    <button
      type="button"
      mat-flat-button
      class="btn"
      aria-label="btnAriaLabel"
      [type]="roleType"
      [ngClass]="classList"
      [color]="btnColor"
      [style.background-color]="btnCustomColor"
      [style.color]="btnTextColor"
      [disabled]="disable"
      (click)="onClickButton($event)"
    >
      <ng-container [ngTemplateOutlet]="btnContent"></ng-container>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="BUTTON_TYPE.STROKED">
    <button
      type="button"
      mat-stroked-button
      class="btn"
      aria-label="btnAriaLabel"
      [type]="roleType"
      [ngClass]="classList"
      [color]="btnColor"
      [style.background-color]="btnCustomColor"
      [style.color]="btnTextColor"
      [disabled]="disable"
      (click)="onClickButton($event)"
    >
      <ng-container [ngTemplateOutlet]="btnContent"></ng-container>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="BUTTON_TYPE.ICON">
    <button
      type="button"
      mat-icon-button
      class="btn"
      aria-label="btnAriaLabel"
      [type]="roleType"
      [ngClass]="classList"
      [color]="btnColor"
      [style.background-color]="btnCustomColor"
      [style.color]="btnTextColor"
      [disabled]="disable"
      (click)="onClickButton($event)"
    >
      <ng-container [ngTemplateOutlet]="btnContent"></ng-container>
    </button>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <button
      type="button"
      mat-button
      class="btn"
      aria-label="btnAriaLabel"
      [type]="roleType"
      [ngClass]="classList"
      [color]="btnColor"
      [style.background-color]="btnCustomColor"
      [style.color]="btnTextColor"
      [disabled]="disable"
      (click)="onClickButton($event)"
    >
      <ng-container [ngTemplateOutlet]="btnContent"></ng-container>
    </button>
  </ng-container>
</ng-container>

<ng-template #btnContent>
  <span
    [matTooltip]="tooltipText"
    [matTooltipPosition]="tooltipPosition"
    class="btn__content"
  >
    <img
      *ngIf="btnIcon"
      width="24px"
      height="24px"
      class="btn__icon"
      [defaultImage]="ICON_SHARED_URL + 'default.svg'"
      [lazyLoad]="ICON_BASE_URL + btnIcon + '.svg'"
      alt="btn icon"
    />
    <ui-text
      *ngIf="btnText"
      class="btn__text"
      [classList]="['placeholder-m', 'placeholder-height-s']"
      [text]="
        btnText && translateContext
          ? (btnTextId | translation : translateContext)
          : btnText
      "
    >
    </ui-text>
  </span>
</ng-template>
