<div class="toggle-slide">
  <mat-slide-toggle
    [formControl]="toggleControl"
    [ngClass]="classList"
    [labelPosition]="labelBefore ? 'before' : 'after'"
    [matTooltip]="tooltipText"
    [matTooltipPosition]="tooltipPosition"
    (change)="onCheckToggleChange($event)"
  >
    <ui-text
      class="toggle__label"
      [classList]="['placeholder-m', 'placeholder-height-s']"
      [text]="label"
    >
    </ui-text>
  </mat-slide-toggle>
</div>
