export interface IOrganization {
  created: string;
  crisisTeamID: number;
  crisisTeamName: string;
  customMainMenuEnabled: boolean;
  externalLink: any;
  helpText: string;
  id: number;
  logo: any;
  menuItems: any[];
  name: string;
  numberOfGroups: number;
  numberOfUsers: number;
  pushSetting: number;
  subOrganizationsEnabled: boolean;
  usersDeleteMethod: number;
  website: string;
  itemName: string;
  parentId?: number;
  useParentEmergencies: boolean;
  managers: any[];
  subOrganizationsForParentOrganization: any[];

  organizationName: string;
  number: string;
  address: string;
  phone: string;

  mainContactName: string;
  mainContactEmail: string;
  mainContactPhoneNumber: string;

  invoicePostalAddress: string;
  invoiceEmail: string;
  invoiceGlnNumber: string;
  invoiceElectronicReference: string;

  MaxNumberOfUsers: number;
  DesktopAppEnabled: boolean;
  alarmGroupsCount: number;

  TwoFactorAuthEnabled: boolean;

  hasImportSettings: boolean;
  EmailSendMethod: number;

  supportEmail: string;
  supportPersonName: string;
  supportPhoneNumber: string;

  sessionLengthDaysMobile: number;
  sessionLengthDaysDesktop: number;
  sessionLengthDaysWeb: number;

  firstSessionReminderDays: number;
  secondSessionReminderDays: number;
  thirdSessionReminderDays: number;

  coForAllUsersLimit?: number;
  musterCreateSetting?: number;
  defaultLanguage?: string;
  coForAllUsersRemaining?: number;
}
