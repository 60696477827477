import { Injectable } from '@angular/core';
import { INoDataInfo } from '@coyards/shared/models';

@Injectable({
  providedIn: 'root',
})
export class NoDataInfoService {
  generateNoDataInfo(data: any, searchValue: any): INoDataInfo | undefined {
    if (!data?.length && searchValue) {
      return {
        title: 'no_search_result_title',
        subtitle: 'no_search_result_subtitle',
        icon: 'no-search-results',
      };
    } else if (!data?.length) {
      return {
        title: 'no_data_title',
        subtitle: 'no_items_found',
        icon: 'no-search-results',
      };
    } else return undefined;
  }
}
