import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoaderType } from '@coyards/shared/models';
import { UiTextComponent } from '../ui-text/ui-text.component';

@Component({
  selector: 'ui-loader',
  standalone: true,
  imports: [CommonModule, UiTextComponent],
  templateUrl: './ui-loader.component.html',
  styleUrls: ['./ui-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiLoaderComponent {
  @Input() type: string = LoaderType.ROUTE;

  ANIMATION_URL = 'assets/animations/';

  loaderType = LoaderType; // Variable reference to our loader Enum
}
