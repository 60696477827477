import { Pipe, PipeTransform } from '@angular/core';

export function mockPipe(options: Pipe): Pipe {
  const metadata: Pipe = {
    name: options.name,
  };

  return <any>Pipe(metadata)(class MockPipe {});
}
@Pipe({ name: 'translation' })
export class MockTranslationPipe implements PipeTransform {
  transform(
    value: any,
    view: string,
    params: { [key: string]: string } = {}
  ): string {
    return value;
  }
}
