export class ResponseResult<T> {
  constructor(private data: T, private errors: any) {}

  isSuccess() {
    return this.errors == null;
  }

  getErrors() {
    return this.errors;
  }

  getData(): T {
    return this.data;
  }
}
