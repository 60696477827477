export const phoneCodes = [
  {
    code: 'AF',
    country: 'Afghanistan',
    tel: '+93',
  },
  {
    code: 'AX',
    country: 'Aland Islands',
    tel: '+358-18',
  },
  {
    code: 'AL',
    country: 'Albania',
    tel: '+355',
  },
  {
    code: 'DZ',
    country: 'Algeria',
    tel: '+213',
  },
  {
    code: 'AS',
    country: 'American Samoa',
    tel: '+1-684',
  },
  {
    code: 'AD',
    country: 'Andorra',
    tel: '+376',
  },
  {
    code: 'AO',
    country: 'Angola',
    tel: '+244',
  },
  {
    code: 'AI',
    country: 'Anguilla',
    tel: '+1-264',
  },
  {
    code: 'AG',
    country: 'Antigua and Barbuda',
    tel: '+1-268',
  },
  {
    code: 'AR',
    country: 'Argentina',
    tel: '+54',
  },
  {
    code: 'AM',
    country: 'Armenia',
    tel: '+374',
  },
  {
    code: 'AW',
    country: 'Aruba',
    tel: '+297',
  },
  {
    code: 'AU',
    country: 'Australia',
    tel: '+61',
  },
  {
    code: 'AT',
    country: 'Austria',
    tel: '+43',
  },
  {
    code: 'AZ',
    country: 'Azerbaijan',
    tel: '+994',
  },
  {
    code: 'BS',
    country: 'Bahamas',
    tel: '+1-242',
  },
  {
    code: 'BH',
    country: 'Bahrain',
    tel: '+973',
  },
  {
    code: 'BD',
    country: 'Bangladesh',
    tel: '+880',
  },
  {
    code: 'BB',
    country: 'Barbados',
    tel: '+1-246',
  },
  {
    code: 'BY',
    country: 'Belarus',
    tel: '+375',
  },
  {
    code: 'BE',
    country: 'Belgium',
    tel: '+32',
  },
  {
    code: 'BZ',
    country: 'Belize',
    tel: '+501',
  },
  {
    code: 'BJ',
    country: 'Benin',
    tel: '+229',
  },
  {
    code: 'BM',
    country: 'Bermuda',
    tel: '+1-441',
  },
  {
    code: 'BT',
    country: 'Bhutan',
    tel: '+975',
  },
  {
    code: 'BO',
    country: 'Bolivia',
    tel: '+591',
  },
  {
    code: 'BQ',
    country: 'Bonaire, Saint Eustatius and Saba ',
    tel: '+599',
  },
  {
    code: 'BA',
    country: 'Bosnia and Herzegovina',
    tel: '+387',
  },
  {
    code: 'BW',
    country: 'Botswana',
    tel: '+267',
  },
  {
    code: 'BR',
    country: 'Brazil',
    tel: '+55',
  },
  {
    code: 'IO',
    country: 'British Indian Ocean Territory',
    tel: '+246',
  },
  {
    code: 'VG',
    country: 'British Virgin Islands',
    tel: '+1-284',
  },
  {
    code: 'BN',
    country: 'Brunei',
    tel: '+673',
  },
  {
    code: 'BG',
    country: 'Bulgaria',
    tel: '+359',
  },
  {
    code: 'BF',
    country: 'Burkina Faso',
    tel: '+226',
  },
  {
    code: 'BI',
    country: 'Burundi',
    tel: '+257',
  },
  {
    code: 'KH',
    country: 'Cambodia',
    tel: '+855',
  },
  {
    code: 'CM',
    country: 'Cameroon',
    tel: '+237',
  },
  {
    code: 'CA',
    country: 'Canada',
    tel: '+1',
  },
  {
    code: 'CV',
    country: 'Cape Verde',
    tel: '+238',
  },
  {
    code: 'KY',
    country: 'Cayman Islands',
    tel: '+1-345',
  },
  {
    code: 'CF',
    country: 'Central African Republic',
    tel: '+236',
  },
  {
    code: 'TD',
    country: 'Chad',
    tel: '+235',
  },
  {
    code: 'CL',
    country: 'Chile',
    tel: '+56',
  },
  {
    code: 'CN',
    country: 'China',
    tel: '+86',
  },
  {
    code: 'CX',
    country: 'Christmas Island',
    tel: '+61',
  },
  {
    code: 'CC',
    country: 'Cocos Islands',
    tel: '+61',
  },
  {
    code: 'CO',
    country: 'Colombia',
    tel: '+57',
  },
  {
    code: 'KM',
    country: 'Comoros',
    tel: '+269',
  },
  {
    code: 'CK',
    country: 'Cook Islands',
    tel: '+682',
  },
  {
    code: 'CR',
    country: 'Costa Rica',
    tel: '+506',
  },
  {
    code: 'HR',
    country: 'Croatia',
    tel: '+385',
  },
  {
    code: 'CU',
    country: 'Cuba',
    tel: '+53',
  },
  {
    code: 'CW',
    country: 'Curacao',
    tel: '+599',
  },
  {
    code: 'CY',
    country: 'Cyprus',
    tel: '+357',
  },
  {
    code: 'CZ',
    country: 'Czech Republic',
    tel: '+420',
  },
  {
    code: 'CD',
    country: 'Democratic Republic of the Congo',
    tel: '+243',
  },
  {
    code: 'DK',
    country: 'Denmark',
    tel: '+45',
  },
  {
    code: 'DJ',
    country: 'Djibouti',
    tel: '+253',
  },
  {
    code: 'DM',
    country: 'Dominica',
    tel: '+1-767',
  },
  {
    code: 'DO',
    country: 'Dominican Republic',
    tel: '+1-809',
  },
  {
    code: 'TL',
    country: 'East Timor',
    tel: '+670',
  },
  {
    code: 'EC',
    country: 'Ecuador',
    tel: '+593',
  },
  {
    code: 'EG',
    country: 'Egypt',
    tel: '+20',
  },
  {
    code: 'SV',
    country: 'El Salvador',
    tel: '+503',
  },
  {
    code: 'GQ',
    country: 'Equatorial Guinea',
    tel: '+240',
  },
  {
    code: 'ER',
    country: 'Eritrea',
    tel: '+291',
  },
  {
    code: 'EE',
    country: 'Estonia',
    tel: '+372',
  },
  {
    code: 'ET',
    country: 'Ethiopia',
    tel: '+251',
  },
  {
    code: 'FK',
    country: 'Falkland Islands',
    tel: '+500',
  },
  {
    code: 'FO',
    country: 'Faroe Islands',
    tel: '+298',
  },
  {
    code: 'FJ',
    country: 'Fiji',
    tel: '+679',
  },
  {
    code: 'FI',
    country: 'Finland',
    tel: '+358',
  },
  {
    code: 'FR',
    country: 'France',
    tel: '+33',
  },
  {
    code: 'GF',
    country: 'French Guiana',
    tel: '+594',
  },
  {
    code: 'PF',
    country: 'French Polynesia',
    tel: '+689',
  },
  {
    code: 'GA',
    country: 'Gabon',
    tel: '+241',
  },
  {
    code: 'GM',
    country: 'Gambia',
    tel: '+220',
  },
  {
    code: 'GE',
    country: 'Georgia',
    tel: '+995',
  },
  {
    code: 'DE',
    country: 'Germany',
    tel: '+49',
  },
  {
    code: 'GH',
    country: 'Ghana',
    tel: '+233',
  },
  {
    code: 'GI',
    country: 'Gibraltar',
    tel: '+350',
  },
  {
    code: 'GR',
    country: 'Greece',
    tel: '+30',
  },
  {
    code: 'GL',
    country: 'Greenland',
    tel: '+299',
  },
  {
    code: 'GD',
    country: 'Grenada',
    tel: '+1-473',
  },
  {
    code: 'GP',
    country: 'Guadeloupe',
    tel: '+590',
  },
  {
    code: 'GU',
    country: 'Guam',
    tel: '+1-671',
  },
  {
    code: 'GT',
    country: 'Guatemala',
    tel: '+502',
  },
  {
    code: 'GG',
    country: 'Guernsey',
    tel: '+44-1481',
  },
  {
    code: 'GN',
    country: 'Guinea',
    tel: '+224',
  },
  {
    code: 'GW',
    country: 'Guinea-Bissau',
    tel: '+245',
  },
  {
    code: 'GY',
    country: 'Guyana',
    tel: '+592',
  },
  {
    code: 'HT',
    country: 'Haiti',
    tel: '+509',
  },
  {
    code: 'HN',
    country: 'Honduras',
    tel: '+504',
  },
  {
    code: 'HK',
    country: 'Hong Kong',
    tel: '+852',
  },
  {
    code: 'HU',
    country: 'Hungary',
    tel: '+36',
  },
  {
    code: 'IS',
    country: 'Iceland',
    tel: '+354',
  },
  {
    code: 'IN',
    country: 'India',
    tel: '+91',
  },
  {
    code: 'ID',
    country: 'Indonesia',
    tel: '+62',
  },
  {
    code: 'IR',
    country: 'Iran',
    tel: '+98',
  },
  {
    code: 'IQ',
    country: 'Iraq',
    tel: '+964',
  },
  {
    code: 'IE',
    country: 'Ireland',
    tel: '+353',
  },
  {
    code: 'IM',
    country: 'Isle of Man',
    tel: '+44-1624',
  },
  {
    code: 'IL',
    country: 'Israel',
    tel: '+972',
  },
  {
    code: 'IT',
    country: 'Italy',
    tel: '+39',
  },
  {
    code: 'CI',
    country: 'Ivory Coast',
    tel: '+225',
  },
  {
    code: 'JM',
    country: 'Jamaica',
    tel: '+1-876',
  },
  {
    code: 'JP',
    country: 'Japan',
    tel: '+81',
  },
  {
    code: 'JE',
    country: 'Jersey',
    tel: '+44-1534',
  },
  {
    code: 'JO',
    country: 'Jordan',
    tel: '+962',
  },
  {
    code: 'KZ',
    country: 'Kazakhstan',
    tel: '+7',
  },
  {
    code: 'KE',
    country: 'Kenya',
    tel: '+254',
  },
  {
    code: 'KI',
    country: 'Kiribati',
    tel: '+686',
  },
  {
    code: 'KW',
    country: 'Kuwait',
    tel: '+965',
  },
  {
    code: 'KG',
    country: 'Kyrgyzstan',
    tel: '+996',
  },
  {
    code: 'LA',
    country: 'Laos',
    tel: '+856',
  },
  {
    code: 'LV',
    country: 'Latvia',
    tel: '+371',
  },
  {
    code: 'LB',
    country: 'Lebanon',
    tel: '+961',
  },
  {
    code: 'LS',
    country: 'Lesotho',
    tel: '+266',
  },
  {
    code: 'LR',
    country: 'Liberia',
    tel: '+231',
  },
  {
    code: 'LY',
    country: 'Libya',
    tel: '+218',
  },
  {
    code: 'LI',
    country: 'Liechtenstein',
    tel: '+423',
  },
  {
    code: 'LT',
    country: 'Lithuania',
    tel: '+370',
  },
  {
    code: 'LU',
    country: 'Luxembourg',
    tel: '+352',
  },
  {
    code: 'MO',
    country: 'Macao',
    tel: '+853',
  },
  {
    code: 'MK',
    country: 'Macedonia',
    tel: '+389',
  },
  {
    code: 'MG',
    country: 'Madagascar',
    tel: '+261',
  },
  {
    code: 'MW',
    country: 'Malawi',
    tel: '+265',
  },
  {
    code: 'MY',
    country: 'Malaysia',
    tel: '+60',
  },
  {
    code: 'MV',
    country: 'Maldives',
    tel: '+960',
  },
  {
    code: 'ML',
    country: 'Mali',
    tel: '+223',
  },
  {
    code: 'MT',
    country: 'Malta',
    tel: '+356',
  },
  {
    code: 'MH',
    country: 'Marshall Islands',
    tel: '+692',
  },
  {
    code: 'MQ',
    country: 'Martinique',
    tel: '+596',
  },
  {
    code: 'MR',
    country: 'Mauritania',
    tel: '+222',
  },
  {
    code: 'MU',
    country: 'Mauritius',
    tel: '+230',
  },
  {
    code: 'YT',
    country: 'Mayotte',
    tel: '+262',
  },
  {
    code: 'MX',
    country: 'Mexico',
    tel: '+52',
  },
  {
    code: 'FM',
    country: 'Micronesia',
    tel: '+691',
  },
  {
    code: 'MD',
    country: 'Moldova',
    tel: '+373',
  },
  {
    code: 'MC',
    country: 'Monaco',
    tel: '+377',
  },
  {
    code: 'MN',
    country: 'Mongolia',
    tel: '+976',
  },
  {
    code: 'ME',
    country: 'Montenegro',
    tel: '+382',
  },
  {
    code: 'MS',
    country: 'Montserrat',
    tel: '+1-664',
  },
  {
    code: 'MA',
    country: 'Morocco',
    tel: '+212',
  },
  {
    code: 'MZ',
    country: 'Mozambique',
    tel: '+258',
  },
  {
    code: 'MM',
    country: 'Myanmar',
    tel: '+95',
  },
  {
    code: 'NA',
    country: 'Namibia',
    tel: '+264',
  },
  {
    code: 'NR',
    country: 'Nauru',
    tel: '+674',
  },
  {
    code: 'NP',
    country: 'Nepal',
    tel: '+977',
  },
  {
    code: 'NL',
    country: 'Netherlands',
    tel: '+31',
  },
  {
    code: 'NC',
    country: 'New Caledonia',
    tel: '+687',
  },
  {
    code: 'NZ',
    country: 'New Zealand',
    tel: '+64',
  },
  {
    code: 'NI',
    country: 'Nicaragua',
    tel: '+505',
  },
  {
    code: 'NE',
    country: 'Niger',
    tel: '+227',
  },
  {
    code: 'NG',
    country: 'Nigeria',
    tel: '+234',
  },
  {
    code: 'NU',
    country: 'Niue',
    tel: '+683',
  },
  {
    code: 'NF',
    country: 'Norfolk Island',
    tel: '+672',
  },
  {
    code: 'KP',
    country: 'North Korea',
    tel: '+850',
  },
  {
    code: 'MP',
    country: 'Northern Mariana Islands',
    tel: '+1-670',
  },
  {
    code: 'NO',
    country: 'Norway',
    tel: '+47',
  },
  {
    code: 'OM',
    country: 'Oman',
    tel: '+968',
  },
  {
    code: 'PK',
    country: 'Pakistan',
    tel: '+92',
  },
  {
    code: 'PW',
    country: 'Palau',
    tel: '+680',
  },
  {
    code: 'PS',
    country: 'Palestinian Territory',
    tel: '+970',
  },
  {
    code: 'PA',
    country: 'Panama',
    tel: '+507',
  },
  {
    code: 'PG',
    country: 'Papua New Guinea',
    tel: '+675',
  },
  {
    code: 'PY',
    country: 'Paraguay',
    tel: '+595',
  },
  {
    code: 'PE',
    country: 'Peru',
    tel: '+51',
  },
  {
    code: 'PH',
    country: 'Philippines',
    tel: '+63',
  },
  {
    code: 'PN',
    country: 'Pitcairn',
    tel: '+870',
  },
  {
    code: 'PL',
    country: 'Poland',
    tel: '+48',
  },
  {
    code: 'PT',
    country: 'Portugal',
    tel: '+351',
  },
  {
    code: 'PR',
    country: 'Puerto Rico',
    tel: '+1-787',
  },
  {
    code: 'QA',
    country: 'Qatar',
    tel: '+974',
  },
  {
    code: 'CG',
    country: 'Republic of the Congo',
    tel: '+242',
  },
  {
    code: 'RE',
    country: 'Reunion',
    tel: '+262',
  },
  {
    code: 'RO',
    country: 'Romania',
    tel: '+40',
  },
  {
    code: 'RU',
    country: 'Russia',
    tel: '+7',
  },
  {
    code: 'RW',
    country: 'Rwanda',
    tel: '+250',
  },
  {
    code: 'BL',
    country: 'Saint Barthelemy',
    tel: '+590',
  },
  {
    code: 'SH',
    country: 'Saint Helena',
    tel: '+290',
  },
  {
    code: 'KN',
    country: 'Saint Kitts and Nevis',
    tel: '+1-869',
  },
  {
    code: 'LC',
    country: 'Saint Lucia',
    tel: '+1-758',
  },
  {
    code: 'MF',
    country: 'Saint Martin',
    tel: '+590',
  },
  {
    code: 'PM',
    country: 'Saint Pierre and Miquelon',
    tel: '+508',
  },
  {
    code: 'VC',
    country: 'Saint Vincent and the Grenadines',
    tel: '+1-784',
  },
  {
    code: 'WS',
    country: 'Samoa',
    tel: '+685',
  },
  {
    code: 'SM',
    country: 'San Marino',
    tel: '+378',
  },
  {
    code: 'ST',
    country: 'Sao Tome and Principe',
    tel: '+239',
  },
  {
    code: 'SA',
    country: 'Saudi Arabia',
    tel: '+966',
  },
  {
    code: 'SN',
    country: 'Senegal',
    tel: '+221',
  },
  {
    code: 'RS',
    country: 'Serbia',
    tel: '+381',
  },
  {
    code: 'SC',
    country: 'Seychelles',
    tel: '+248',
  },
  {
    code: 'SL',
    country: 'Sierra Leone',
    tel: '+232',
  },
  {
    code: 'SG',
    country: 'Singapore',
    tel: '+65',
  },
  {
    code: 'SX',
    country: 'Sint Maarten',
    tel: '+599',
  },
  {
    code: 'SK',
    country: 'Slovakia',
    tel: '+421',
  },
  {
    code: 'SI',
    country: 'Slovenia',
    tel: '+386',
  },
  {
    code: 'SB',
    country: 'Solomon Islands',
    tel: '+677',
  },
  {
    code: 'SO',
    country: 'Somalia',
    tel: '+252',
  },
  {
    code: 'ZA',
    country: 'South Africa',
    tel: '+27',
  },
  {
    code: 'KR',
    country: 'South Korea',
    tel: '+82',
  },
  {
    code: 'SS',
    country: 'South Sudan',
    tel: '+211',
  },
  {
    code: 'ES',
    country: 'Spain',
    tel: '+34',
  },
  {
    code: 'LK',
    country: 'Sri Lanka',
    tel: '+94',
  },
  {
    code: 'SD',
    country: 'Sudan',
    tel: '+249',
  },
  {
    code: 'SR',
    country: 'Suriname',
    tel: '+597',
  },
  {
    code: 'SZ',
    country: 'Swaziland',
    tel: '+268',
  },
  {
    code: 'SE',
    country: 'Sweden',
    tel: '+46',
  },
  {
    code: 'CH',
    country: 'Switzerland',
    tel: '+41',
  },
  {
    code: 'SY',
    country: 'Syria',
    tel: '+963',
  },
  {
    code: 'TW',
    country: 'Taiwan',
    tel: '+886',
  },
  {
    code: 'TJ',
    country: 'Tajikistan',
    tel: '+992',
  },
  {
    code: 'TZ',
    country: 'Tanzania',
    tel: '+255',
  },
  {
    code: 'TH',
    country: 'Thailand',
    tel: '+66',
  },
  {
    code: 'TG',
    country: 'Togo',
    tel: '+228',
  },
  {
    code: 'TK',
    country: 'Tokelau',
    tel: '+690',
  },
  {
    code: 'TO',
    country: 'Tonga',
    tel: '+676',
  },
  {
    code: 'TT',
    country: 'Trinidad and Tobago',
    tel: '+1-868',
  },
  {
    code: 'TN',
    country: 'Tunisia',
    tel: '+216',
  },
  {
    code: 'TR',
    country: 'Turkey',
    tel: '+90',
  },
  {
    code: 'TM',
    country: 'Turkmenistan',
    tel: '+993',
  },
  {
    code: 'TC',
    country: 'Turks and Caicos Islands',
    tel: '+1-649',
  },
  {
    code: 'TV',
    country: 'Tuvalu',
    tel: '+688',
  },
  {
    code: 'VI',
    country: 'U.S. Virgin Islands',
    tel: '+1-340',
  },
  {
    code: 'UG',
    country: 'Uganda',
    tel: '+256',
  },
  {
    code: 'UA',
    country: 'Ukraine',
    tel: '+380',
  },
  {
    code: 'AE',
    country: 'United Arab Emirates',
    tel: '+971',
  },
  {
    code: 'GB',
    country: 'United Kingdom',
    tel: '+44',
  },
  {
    code: 'US',
    country: 'United States',
    tel: '+1',
  },
  {
    code: 'UM',
    country: 'United States Minor Outlying Islands',
    tel: '+1',
  },
  {
    code: 'UY',
    country: 'Uruguay',
    tel: '+598',
  },
  {
    code: 'UZ',
    country: 'Uzbekistan',
    tel: '+998',
  },
  {
    code: 'VU',
    country: 'Vanuatu',
    tel: '+678',
  },
  {
    code: 'VA',
    country: 'Vatican',
    tel: '+379',
  },
  {
    code: 'VE',
    country: 'Venezuela',
    tel: '+58',
  },
  {
    code: 'VN',
    country: 'Vietnam',
    tel: '+84',
  },
  {
    code: 'WF',
    country: 'Wallis and Futuna',
    tel: '+681',
  },
  {
    code: 'EH',
    country: 'Western Sahara',
    tel: '+212',
  },
  {
    code: 'YE',
    country: 'Yemen',
    tel: '+967',
  },
  {
    code: 'ZM',
    country: 'Zambia',
    tel: '+260',
  },
  {
    code: 'ZW',
    country: 'Zimbabwe',
    tel: '+263',
  },
];
