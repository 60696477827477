import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AccountInfo,
  ILoginForm,
  IResetPasswordPayload,
} from '@coyards/shared/models';
import {
  RequestHelperService,
  ResponseHandlerService,
} from '@coyards/shared/services';
import { ResponseResult } from '@coyards/shared/utilities';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { logoutAction } from '../modules/main/store';
import { MainFacade } from '../modules/main/store/main.facade';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public token: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private requestHelper: RequestHelperService,
    private responseHandler: ResponseHandlerService,
    private mainFacade: MainFacade,
    private store: Store
  ) {
    if (sessionStorage.getItem('rvt') === 'undefined') {
      sessionStorage.removeItem('currentUser');
      sessionStorage.removeItem('groupData');
      sessionStorage.removeItem('email');
      this.router.navigate(['/login']);
    }
  }

  updateCurrentUser(userToUpdate: AccountInfo) {
    sessionStorage.setItem('currentUser', JSON.stringify(userToUpdate));
    this.token = userToUpdate && userToUpdate.token;
    this.mainFacade.setLoggedUser(userToUpdate);
  }

  updateCurrentUserFromServer() {
    this.getUserAccountInfo().subscribe((response) => {
      if (response.isSuccess()) {
        this.updateCurrentUser(response.getData());
      }
    });
  }

  login(loginData: ILoginForm) {
    return this.http
      .post(
        this.requestHelper.getUrl('Account/LoginAdmin'),
        loginData,
        this.requestHelper.getOptions()
      )
      .pipe(
        map((response) => {
          const result =
            this.responseHandler.validateResponse<AccountInfo>(response);
          if (result.isSuccess()) {
            const data = result.getData();
            if (data['TwoFactorAuthCodeRequired'] === true) {
              return data;
            }
            // const token = data['token'];
            const id = data['id'];
            if (id) {
              // store username and jwt token in local storage to keep user logged in between page refreshes
              this.updateCurrentUser(data);

              // return true to indicate successful login
              return { accountInfo: data };
            } else {
              // return false to indicate failed login
              return false;
            }
          } else {
            return false;
          }
        })
      );
  }

  antiForgeryToken() {
    return this.http
      .get(
        this.requestHelper.getUrl('Account/AntiForgeryToken'),
        this.requestHelper.getOptions()
      )
      .pipe(
        map((response) => {
          const result =
            this.responseHandler.validateResponse<unknown>(response);
          if (result.isSuccess()) {
            const data = result.getData();
            sessionStorage.setItem('rvt', data['requestVerificationToken']);
            return data;
          }
          return false;
        })
      );
  }

  logout() {
    // set account info to null to prevent going back after logout
    this.mainFacade.setLoggedUser(null);

    // clear token remove user from local storage to log user out
    return this.http
      .post(
        this.requestHelper.getUrl(`Account/Logout`),
        '',
        this.requestHelper.getOptions()
      )
      .pipe(map(() => this.cleanStorages()));
  }

  cleanStorages() {
    this.token = null;
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('groupData');
    sessionStorage.removeItem('email');
    sessionStorage.setItem('rvt', null);
    // clear all ngrx stores
    this.store.dispatch(logoutAction());
  }

  getUserAccountInfo(): Observable<ResponseResult<AccountInfo>> {
    return this.responseHandler.defaultResponseProcessing(
      this.http.get<AccountInfo>(
        this.requestHelper.getUrl(`Account/Info`),
        this.requestHelper.getOptions({})
      )
    );
  }

  sendResetPasswordCode(email: string) {
    return this.responseHandler.defaultResponseProcessing<unknown>(
      this.http.post<unknown>(
        this.requestHelper.getUrl(`Account/Password/Token`),
        { email },
        this.requestHelper.getOptions({})
      )
    );
  }

  resetPassword(payload: IResetPasswordPayload) {
    return this.responseHandler.defaultResponseProcessing<unknown>(
      this.http.post<unknown>(
        this.requestHelper.getUrl(`Account/Password/Reset`),
        payload,
        this.requestHelper.getOptions({})
      )
    );
  }
}
