import { ITablePagination } from './pagination';

export interface IGroupsFilter {
  sort: string;
  asc: 'asc' | 'desc';
  search: string;
  pagination: ITablePagination;
}

export interface IGroupsRequestFilter {
  sort?: string;
  asc?: 'asc' | 'desc';
  search?: string;
  skip?: number;
  limit?: number;
}

export interface IGroup {
  municipality: string | null;
  county: string | null;
  groupType: number;
  groupMembersCount: number | null;
  totalMessageCount: number | null;
  longitude: number;
  latitude: number;
  lastSentMessage: string | null;
  admins: number | null;
  membersInGroup?: number | null;
  country: string | null;
  region: string | null;
  town: string | null;
  subLocality: string | null;
  active: boolean;
  memberIds: number[] | null;
  locationname: string | null;
  locationID: number;
  openCalendarWriteAccess: boolean;
  searchable: boolean;
  adminSetsInfo: boolean;
  member: boolean;
  admin: boolean | null;
  hidden: boolean;
  description: string | null;
  imageFileName: string | null;
  additionalInfoType: number;
  id: number;
  name: string;
  created: string;
  GroupID: number;
  type: number;
  deleted?: boolean | null;
}

export interface IUserGroup {
  id: number;
  name: string;
  admin: boolean;
  additionalInfoType: number;
  created: string;
  locationID: 1;
  openCalendarWriteAccess: boolean;
  searchable: boolean;
  adminSetsInfo: boolean;
  member: boolean;
  hidden: boolean;
  description: string | null;
  imageFileName: string | null;
  GroupID: number;
  type: number;
  memberAdditionalInfo?: string;
}

export interface IGroupsResponse {
  totalCount: number;
  groups: IGroup[];
}

export enum GroupAdditionalInfoType {
  Address,
  Title,
  Boatplace,
  Flat,
  Floor,
  Cottage,
  House,
  Allotment,
  Parking,
  BoatDock,
  BoatBerth,
  SummerBerth,
  WinterSpace,
  BoatClub,
  Marina,
  ShopName,
  CompanyName,
  Association,
  CampingSpace,
  Location,
  Organization,
}

export enum GroupAdditionalInfoTypeCategory {
  NeighbourhoodCooperation,
  BoatCooperation,
  CorporateCooperation,
  Other,
}

export interface AdditionalInfoData {
  infoId: number;
  infoTypeName: string;
  infoCategoryName: string;
}

export interface IGroupMember {
  created: string;
  locationID: number;
  registrationEmailNotSent: boolean;
  registrationEmailSent: string;
  registrationTokenExpired: boolean;
  groupID: number;
  userID: number;
  info: string;
  admin: boolean;
  lastAccess: string;
  phoneNumber: string;
  userName: string;
  userDeleted: boolean;
  userEmail: string;
  joined: string;
  photoFileName: string;
  phoneVerified: boolean;
  emailVerified: boolean;
  lastAppVersionLoggedIn: string;
}

export interface IGroupAdminsFilter {
  admins: boolean | null;
}

export interface IUpdateGroup {
  name?: string;
  description?: string;
  longitude?: number;
  latitude?: number;
  AdditionalInfoType?: number | string;
  locationID?: number;
  userid?: number;
  openCalendarWriteAccess?: boolean;
  searchable?: boolean;
  adminSetsInfo?: boolean;
  groupType?: number;
  active?: boolean;
  imageFileName?: string;
}

export interface IGroupMemberUpdateData {
  info?: string;
  admin?: boolean;
  userEmail?: string;
  phoneNumber?: string;
}

export interface IInviteMemberData {
  groupID?: number;
  userEmails?: string[];
  phoneNumbers?: string[];
  userIDs?: number[];
  message?: string;
}

export interface IInviteMemberResponse {
  registered: number;
  unregistered: number;
  alreadyInvited: number;
  existingGroupMembers: string;
  alreadyInvitedList: string;
}

export interface IGroupListTableSupportData {
  adminNamesMap: Map<number, string>;
}

export interface IGroupInvitation {
  id: number;
  groupID: number;
  groupName: string;
  groupPhoto: string;
  groupDescription: string;
  groupAdditionalInfoType: number;
  senderUserID: number;
  senderDisplayName: string;
  recipientUserID: number;
  recipientEmail: string;
  recipientDisplayName: string;
  recipientPhone: string;
  sent: string;
  message: string;
  answered: string;
  accepted: boolean;
}

export interface IGroupInvitationFilter {
  answered: boolean | null;
  showOnlyPending: boolean;
}
