import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { ILanguage } from '@coyards/shared/models';
import { TranslationsService } from '@coyards/shared/services';
import { Subscription } from 'rxjs';

@Pipe({
  name: 'translation',
  pure: false,
  standalone: true,
})
export class TranslationPipe implements PipeTransform, OnDestroy {
  ICON_SHARED_URL = 'assets/icons/shared/';

  lang: ILanguage;
  val: any;
  private originalValue: any;
  private langChangeSub: Subscription;

  constructor(
    private translationsService: TranslationsService,
    private cdr: ChangeDetectorRef
  ) {
    this.langChangeSub = this.translationsService.currentLang.subscribe(() =>
      this.cdr.markForCheck()
    );
  }

  transform(
    value: any,
    view: string,
    params: { [key: string]: string } = {}
  ): string {
    const currentLanguage = this.translationsService.getCurrentLanguage();
    if (
      !this.lang ||
      this.lang.locale !== currentLanguage.locale ||
      this.originalValue !== value
    ) {
      this.lang = currentLanguage;
      this.val = this.translationsService.translate(
        value,
        view,
        this.lang,
        params
      );
      this.originalValue = value;
    }
    return this.val && this.val !== value ? this.val : '...';
  }

  ngOnDestroy() {
    this.langChangeSub.unsubscribe();
  }
}

@Pipe({
  name: 'namesTranslation',
  pure: false,
})
export class NamesTranslationPipe implements PipeTransform {
  lang: ILanguage;
  val: any = null;

  constructor(private translationsService: TranslationsService) {}

  private isChanged(lang?: ILanguage) {
    return lang && (!this.lang || this.lang.locale !== lang.locale);
  }

  transform(value: any[], langField: string = 'Language'): any {
    if (!value || value.length === 0) {
      return null;
    }
    const currentLanguage = this.translationsService.getCurrentLanguage();
    if (!this.val || this.isChanged(currentLanguage)) {
      this.lang = currentLanguage;
      this.val = value.find(
        (name) => !this.lang || name[langField] === this.lang.locale
      );
    }
    return this.val;
  }
}
