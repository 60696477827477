<div *ngIf="toast" class="toast-content">
  <div
    class="toast"
    [ngClass]="{
      'toast--success': toast?.status === 'success',
      'toast--error': toast?.status === 'danger',
      'toast--warn': toast?.status === 'warn'
    }"
  >
    <ui-text
      class="toast__text"
      [classList]="['placeholder-m', 'placeholder-height-s']"
      [text]="
        toast?.textId && toast?.translationsContext
          ? (toast?.textId | translation : toast?.translationsContext)
          : toast?.text
      "
    >
    </ui-text>
    <button class="toast__close" (click)="onClose()">
      <img
        width="12px"
        height="12px"
        [defaultImage]="ICON_SHARED_URL + 'default.svg'"
        [lazyLoad]="ICON_BASE_URL + closeIcon + '.svg'"
        alt="Input icon"
      />
    </button>
  </div>
</div>
