import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@Component({
  selector: 'ui-tooltip-icon',
  standalone: true,
  imports: [
    CommonModule,
    LazyLoadImageModule,
    MatTooltipModule,
    MatButtonModule,
  ],
  templateUrl: './ui-tooltip-icon.component.html',
  styleUrls: ['./ui-tooltip-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiTooltipIconComponent {
  @Input() givenName: string;
  @Input() iconName: string; // Relative path form assets/icons folder
  @Input() iconTooltipText: string;
  @Input() iconTooltipPosition: TooltipPosition = 'below';
  @Input() classList: string[];
  @Input() tooltipClassList: string[];
  @Input() ariaHidden = false;
  @Input() ariaLabel = '';

  @Output() clickEvent = new EventEmitter();

  ICON_SHARED_URL = 'assets/icons/shared/';

  onClick(event) {
    this.clickEvent.emit(event);
  }
}
