<div class="autocomp">
  <form class="example-form">
    <div
      class="autocomp__wrapper"
      [ngClass]="[isFloatLabel ? 'autocomp__float-label' : '']"
    >
      <label
        class="autocomp__label"
        [ngClass]="[isFloatLabel && value ? 'floated' : '']"
      >
        <ui-text
          [classList]="['placeholder-s', 'placeholder-height-s']"
          [text]="isFloatLabel ? labelText : inputLabel"
        >
        </ui-text>
      </label>
      <input
        type="text"
        placeholder=""
        aria-label=""
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        class="autocomp__input truncate"
        [ngClass]="classList"
        [class.disable]="disable"
        [formControl]="searchControl"
        [matAutocomplete]="options"
        [value]="value"
        (keyup)="keyUp($event)"
      />
      <button
        *ngIf="!searchControl.value || !cleanIcon"
        class="autocomp__arrow"
        [ngClass]="{ disable: disable }"
        (click)="openPanel($event)"
      >
        <img
          width="24px"
          height="24px"
          [defaultImage]="ICON_SHARED_URL + 'default.svg'"
          [lazyLoad]="ICON_BASE_URL + 'drop-arrow.svg'"
          alt="btn image"
        />
      </button>
      <button
        *ngIf="cleanIcon && searchControl.value"
        class="autocomp__arrow autocomp__clean"
        [class.disable]="disable"
        (click)="cleanField($event)"
      >
        <img
          width="18px"
          height="18px"
          [defaultImage]="ICON_SHARED_URL + 'default.svg'"
          [lazyLoad]="ICON_BASE_URL + 'alert_close.svg'"
          alt="btn image"
        />
      </button>
    </div>
    <mat-autocomplete
      #options="matAutocomplete"
      class="autocomp__panel"
      [displayWith]="displayWith.bind(this)"
      (optionSelected)="onSelectEvent($event)"
    >
      <ng-container
        *ngFor="let option of filteredOptions$ | async; let lastIndex = last"
      >
        <mat-option
          [value]="option"
          [ngClass]="allowSelection(option[optionKey])"
        >
          {{ optionKey ? option[optionKey] : option }}
        </mat-option>
        <ui-divider *ngIf="!lastIndex" [classList]="['divider-nogap']">
        </ui-divider>
      </ng-container>
    </mat-autocomplete>
  </form>
</div>
