import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { environment } from '@coyards/shared/environments';
import {
  notNumberRegExp,
  numberRegExp,
  phoneCodes,
} from '@coyards/shared/utilities';
import find from 'lodash-es/find';
import { Subject, filter, takeUntil } from 'rxjs';
import { UiFieldTextComponent } from '../ui-field-text/ui-field-text.component';
import { UiSelectComponent } from '../ui-select/ui-select.component';

@Component({
  selector: 'ui-phone-number',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UiSelectComponent,
    UiFieldTextComponent,
  ],
  templateUrl: './ui-phone-number.component.html',
  styleUrls: ['./ui-phone-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UiPhoneNumberComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiPhoneNumberComponent
  implements OnChanges, OnInit, ControlValueAccessor, OnDestroy
{
  @Input() phoneValue: string;
  @Input() codeLabelText: string;
  @Input() numberLabelText: string;
  @Input() hasError: boolean;
  @Input() errorText: string;

  @Output() changeEvent = new EventEmitter();
  @Output() enterEvent = new EventEmitter();

  private ngUnsubscribe = new Subject();

  numberControl = new FormControl(null, []);

  defaultCode = environment.defaultPhoneCode;
  defaultPhoneCode = { name: 'SE', country: 'Sweden', tel: '+46' };
  phoneControl = new FormControl('', [Validators.required]);
  codeModel = { name: '', country: '', tel: '' };
  numberModel = null;
  phoneCodes = [];
  codeModelId = 209; // set default code value to Sweden

  /////////////////////////////////////////
  // Start of control value accessor assets
  value;
  disabled = false;
  onChange = (value: any) => null;
  onTouched = () => null;

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    if (value !== null && value !== undefined) {
      this.processPhoneValue(value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.phoneControl.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((phoneNumber) => !!phoneNumber)
      )
      .subscribe((phoneNumber) => fn(phoneNumber));
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  // End of control value accessor assets
  ///////////////////////////////////////

  ngOnChanges(changes: SimpleChanges): void {
    if ('phoneValue' in changes) {
      if (this.phoneValue !== null && this.phoneValue !== undefined) {
        this.processPhoneValue();
      }
    }
  }

  ngOnInit(): void {
    this.initializeData();
    if (this.phoneValue !== null && this.phoneValue !== undefined) {
      this.processPhoneValue();
    }
  }

  private setInitialCodeValue() {
    // set initial value
    this.codeModelId = find(
      this.phoneCodes,
      (phoneCode) => phoneCode.tel === this.codeModel?.tel
    )?.id;
  }

  private initializeData() {
    this.phoneCodes = phoneCodes.map((c, index) => {
      c['name'] = c['country'] + ' ' + c['tel'];
      c['id'] = index;
      return c;
    });
    this.codeModel =
      this.getCodeByNumber(this.defaultCode) ?? this.defaultPhoneCode;
  }

  private processPhoneValue(phoneString: string = this.phoneValue) {
    let idx = 2;
    let result =
      this.codeModel && phoneString.indexOf(this.codeModel?.tel) === 0
        ? this.codeModel
        : null;
    while (!result && idx <= phoneString.length) {
      result = this.getCodeByNumber(phoneString.slice(0, idx));
      idx++;
    }
    this.codeModel = result ? result : this.getCodeByNumber(this.defaultCode);
    this.numberModel = result
      ? phoneString.slice(this.codeModel.tel.length)
      : phoneString;

    this.setInitialCodeValue();

    this.updateValue();
  }

  private updateValue() {
    const code = String(this.codeModel?.tel);
    const number = String(this.numberModel);
    let fullNumber = null;

    if (
      code === 'undefined' ||
      number === 'undefined' ||
      number === 'null' ||
      !code ||
      !number
    ) {
      fullNumber = null;
    } else {
      fullNumber = [code, number].join('');
    }

    this.phoneControl.setValue(fullNumber);
    if (this.value !== fullNumber) {
      this.value = fullNumber;
      this.onChange(this.value);
      this.changeEvent.emit({
        name: this.codeModel,
        number: this.numberModel,
        phone: this.phoneControl.value,
      });
    }
  }

  private getCodeByNumber(num: string) {
    return this.phoneCodes.find((x) => x.tel === num);
  }

  onChangeCode(phoneCodeId: number) {
    const phoneCode = find(
      this.phoneCodes,
      (phoneCode) => phoneCode.id === phoneCodeId
    );

    if (phoneCode) {
      this.codeModel = phoneCode;
      this.updateValue();
    }
  }

  onNumberChanged(number: string) {
    if (number === '' || number.match(numberRegExp)) {
      this.numberModel = number;
    } else {
      const validatedNumber = number
        .replace(/^0/, '')
        .replace(notNumberRegExp, '');
      this.numberControl.setValue(validatedNumber);
      this.numberModel = validatedNumber;
    }

    this.updateValue();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
