import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { UiTextComponent } from '../ui-text/ui-text.component';

@Component({
  selector: 'ui-field-password',
  standalone: true,
  imports: [
    CommonModule,
    UiTextComponent,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    LazyLoadImageModule,
  ],
  templateUrl: './ui-field-password.component.html',
  styleUrls: ['./ui-field-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UiFieldPasswordComponent,
    },
  ],
})
export class UiFieldPasswordComponent
  implements OnChanges, ControlValueAccessor
{
  @Input() placeholder: string;
  @Input() placeholderTextId: string;
  @Input() inputValue: string;
  @Input() iconName: string;
  @Input() labelText: string;
  @Input() labelTextId: string;
  @Input() hasError: boolean;
  @Input() errorText: string;
  @Input() disable: boolean;
  @Input() classList: string[];
  @Input() customBgColor: string;
  @Input() customColor: string;

  @Output() enterEvent = new EventEmitter();

  ICON_BASE_URL = 'assets/icons/field/';
  ICON_SHARED_URL = 'assets/icons/shared/';

  passwordFormControl = new FormControl();
  isTextMode = false;

  /////////////////////////////////////////
  // Start of control value accessor assets
  value: UiFieldPasswordComponent;
  disabled = false;
  onChange = (value: any) => null;
  onTouched = () => null;

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    if (value !== null && value !== undefined) {
      this.value = value;
      this.passwordFormControl.setValue(value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.passwordFormControl.valueChanges.subscribe(fn(this.value));
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  // End of control value accessor assets
  ///////////////////////////////////////

  ngOnChanges(changes: SimpleChanges): void {
    if ('disable' in changes) {
      changes['disable'].currentValue
        ? this.passwordFormControl.disable()
        : this.passwordFormControl.enable();
    }
  }

  private updateValue() {
    this.value = this.passwordFormControl.value;
    this.onChange(this.value);
  }

  keyUp() {
    this.updateValue();
  }

  keyDown(event: KeyboardEvent) {
    this.updateValue();
    if (event.key === 'Enter') {
      this.enterEvent.emit(this.value);
    }
  }

  toggleText() {
    this.isTextMode = !this.isTextMode;
  }
}
