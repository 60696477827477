import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UiTextComponent } from '../ui-text/ui-text.component';
import { UiTooltipIconComponent } from '../ui-tooltip-icon/ui-tooltip-icon.component';

@Component({
  selector: 'ui-preset-title',
  standalone: true,
  imports: [CommonModule, UiTextComponent, UiTooltipIconComponent],
  templateUrl: './ui-preset-title.component.html',
  styleUrls: ['./ui-preset-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiPresetTitleComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() label: string;
  @Input() para: string;
  @Input() titleClassList: string[];
  @Input() subtitleClassList: string[];
  @Input() labelClassList: string[];
  @Input() paraClassList: string[];
  @Input() tooltipIcon = 'tooltip_info.svg';
  @Input() tooltipText: string;
}
