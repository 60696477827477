import { ITablePagination } from './pagination';
import { IRole } from './role';

export interface IUsersFilter {
  sort?: string;
  asc?: 'asc' | 'desc';
  search?: string;
  pagination?: ITablePagination;
  roleFilter?: IUsersFilterOption;
}

export interface IUsersFilterOption {
  id: number;
  name: string;
  role: string | null;
}

export interface IUsersRequestFilter {
  sort?: string;
  asc?: string;
  search?: string;
  skip?: number;
  limit?: number;
  role?: string;
  sortingRole?: string;
}

export interface IUser {
  adminMunicipalitiesCount: number;
  adminCountiesCount: number;
  id: number;
  name: string;
  email: string;
  created: string;
  lastaccess: string | null;
  lastAccessAgent: string | null;
  lastAccessIP: string | null;
  groupscount: number;
  roles: IRole[];
  location: string | null;
  phoneNumber: string;
  activeTokens: number;
  photoFileName: string | null;
  sortOrderInUI: number;
  lastAlarmTestDate: string;
  hasAlarmTests: boolean;
  canBeEdited: boolean;
  messagescount: number;
  title: string;
  phoneVerified: boolean;
  twoFactorAuthPhoneNumber?: string;
  broadcastAdmin?: boolean;
  adminCounties?: IAdminMunicipalityCounty[];
  adminMunicipalities?: IAdminMunicipalityCounty[];
  groupNames?: string[];
}

export interface IUsersResponse {
  total: number;
  users: IUser[];
}

export interface IAdminMunicipalityCounty {
  name?: string;
  geoName?: string;
  userID?: number;
  referralOnly?: boolean;
}

export interface IUserUpdateData {
  email?: string;
  phoneNumber?: string;
  twoFactorAuthPhoneNumber?: string;
  photoFileName?: string;
}
