import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { Pipe, PipeTransform } from '@angular/core';
import { SelectionEnum } from '@coyards/shared/models';

@Pipe({
  name: 'selection',
  standalone: true,
})
export class SelectionPipe implements PipeTransform {
  transform(
    name: SelectionEnum,
    selection: SelectionModel<unknown>,
    triggerUpdate: object,
    rowData?: unknown
  ): boolean {
    switch (name) {
      case SelectionEnum.HAS_VALUE:
        return selection.hasValue();
      case SelectionEnum.IS_SELECTED:
        return selection.isSelected(rowData);
    }
  }
}
