import { IPermission } from './permission';

export class AccountInfo {
  authenticationType: string;
  crisisTeamID: number;
  email: string;
  id: number;
  menuItems: any[];
  name: string;
  preferredLanguage: string;
  photoFileName: string;
  roles: string[];
  sso_provider?: number;
  token: string;
  permissions: IPermission[];
  personalAlarmId: number;
  coForAllUsersRemaining: number;
  isCrisisTeamMember: boolean;
  loggedAsAdmin: boolean;
  readDisclaimer: boolean;
  EmailConfirmed: boolean;
  PhoneNumberConfirmed: boolean;
}
