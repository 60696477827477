import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IContact } from '@coyards/shared/models';
import { TranslationPipe, TruncatePipe } from '@coyards/shared/pipes';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { UiButtonComponent } from '../ui-button/ui-button.component';
import { UiPresetTitleComponent } from '../ui-preset-title/ui-preset-title.component';

@Component({
  selector: 'ui-contact-card',
  standalone: true,
  imports: [
    CommonModule,
    LazyLoadImageModule,
    UiPresetTitleComponent,
    UiButtonComponent,
    TranslationPipe,
    MatTooltipModule,
    TruncatePipe,
  ],
  templateUrl: './ui-contact-card.component.html',
  styleUrls: ['./ui-contact-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiContactCardComponent {
  ICON_BASE_URL = 'assets/icons/card/';
  ICON_SHARED_URL = 'assets/icons/shared/';

  @Input() data: IContact;
  @Input() canEdit = false; // optional
  @Input() nameClassList: string[] = [
    'text-l',
    'text-height-34',
    'text-margin-0',
  ]; // optional
  @Input() registrationDateClassList: string[] = [
    'text-xxs',
    'text-margin-0',
    'text-color-grey-400',
  ]; // optional
  @Input() contactTextClassList: string[] = [
    'text-xxs',
    'text-weight-500',
    'text-margin-0',
    'text-color-grey-700',
  ]; // optional
  @Output() editEvent = new EventEmitter();

  onEdit() {
    this.editEvent.emit();
  }
}
