<ng-container [ngSwitch]="cellConfig.type">
  <ng-container *ngSwitchCase="TableCellType.TEXT">
    <span
      [ngClass]="cellConfig.dataClass"
      [matTooltip]="cellConfig?.textTooltip"
      [matTooltipClass]="{ 'tooltip-with-breaks': true }"
      >{{ cellConfig.text }}
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="TableCellType.IMAGE_AND_TEXT">
    <span [ngClass]="cellConfig.dataClass">
      <img
        *ngIf="cellConfig?.iconBeforeText"
        [ngClass]="cellConfig.imageClass"
        [defaultImage]="ICON_SHARED_URL + 'default-24px.svg'"
        [lazyLoad]="ICON_TABLE_URL + cellConfig.iconBeforeText"
        [matTooltip]="cellConfig?.iconBeforeTextToolTip"
        [matTooltipClass]="{ 'tooltip-with-breaks': true }"
      />
      <span
        class="data__truncate-long-text"
        [matTooltip]="cellConfig?.textTooltip"
        [matTooltipClass]="{ 'tooltip-with-breaks': true }"
        >{{ cellConfig.text }}
      </span>
      <img
        *ngIf="cellConfig?.icon"
        [ngClass]="cellConfig.imageClass"
        [defaultImage]="ICON_SHARED_URL + 'default-24px.svg'"
        [lazyLoad]="ICON_TABLE_URL + cellConfig.icon"
        [matTooltip]="cellConfig?.iconTooltip"
        [matTooltipClass]="{ 'tooltip-with-breaks': true }"
      />
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="TableCellType.HIGHLIGHT">
    <span [ngClass]="cellConfig.dataClass">
      <span
        *ngIf="
          cellConfig.highlight.highlightedItems.length;
          else nonHighlightedText
        "
        [matTooltip]="cellConfig?.textTooltip"
        [matTooltipClass]="{ 'tooltip-with-breaks': true }"
      >
        <ng-container
          *ngFor="
            let item of cellConfig.highlight.nonHighlightedItems;
            let index = index;
            let last = last
          "
          >{{ item
          }}<span class="data__text-green" *ngIf="!last">{{
            cellConfig.highlight.highlightedItems[index]
          }}</span>
        </ng-container>
      </span>

      <ng-template #nonHighlightedText>
        <span
          [ngClass]="cellConfig.dataClass"
          [matTooltip]="cellConfig?.textTooltip"
          [matTooltipClass]="{ 'tooltip-with-breaks': true }"
          >{{ cellConfig.text }}
        </span>
      </ng-template>

      <img
        *ngIf="cellConfig?.icon"
        width="24px"
        [ngClass]="cellConfig.imageClass"
        [defaultImage]="ICON_SHARED_URL + 'default.svg'"
        [lazyLoad]="ICON_TABLE_URL + cellConfig.icon"
        [matTooltip]="cellConfig?.iconTooltip"
        [matTooltipClass]="{ 'tooltip-with-breaks': true }"
    /></span>
  </ng-container>
</ng-container>
