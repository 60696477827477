/* eslint-disable @typescript-eslint/no-explicit-any */
import hasIn from 'lodash-es/hasIn';
import isNil from 'lodash-es/isNil';

export class DetectChanges {
  public static compareObjects(
    initialData: { [key: string]: any },
    actualData: { [key: string]: any }
  ): boolean {
    let hasProps = false;
    let hasChanges = false;

    for (const key in initialData) {
      hasProps =
        (hasIn(actualData, key) &&
          !isNil(actualData[key]) &&
          !!actualData[key]) ||
        (hasIn(initialData, key) &&
          !isNil(initialData[key]) &&
          !!initialData[key]);
      hasChanges = actualData[key] != initialData[key];

      if (hasProps) {
        if (hasChanges) {
          break;
        }
      }
    }

    return hasProps && hasChanges;
  }

  public static compareObjectsWithDiffProps(
    initialData: [{ [key: string]: any }, Array<string>],
    actualData: [{ [key: string]: any }, Array<string>]
  ): boolean | void {
    if (!initialData[0] || !actualData[0]) {
      return;
    }

    let hasChanges = false;
    for (const [i, key] of initialData[1].entries()) {
      if (initialData[0][key] || actualData[0][actualData[1][i]]) {
        hasChanges = initialData[0][key] !== actualData[0][actualData[1][i]];
      }

      if (hasChanges) {
        break;
      }
    }

    return hasChanges;
  }

  public static compareArrays(
    initialData: (string | number)[],
    actualData: (string | number)[]
  ): boolean {
    if (!initialData || !actualData) {
      return;
    }

    return (
      initialData.length !== actualData.length ||
      !actualData.every((item) => initialData.includes(item))
    );
  }
}
