export class AlertMessage {
  alertTitle = '';
  alertMsg = '';
  messageText = '';
  translationsContext = '';

  constructor(data: {
    title?: string;
    msg?: string;
    msgText?: string;
    translationsContext?: string;
  }) {
    this.alertTitle = data.title;
    this.alertMsg = data.msg;
    this.messageText = data.msgText;
    this.translationsContext = data.translationsContext;
  }
}

export enum MessageType {
  SUCCESS = 'success',
  DANGER = 'danger',
  WARN = 'warn',
}
