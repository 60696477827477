<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="PLACEHOLDER_TYPE.TYPE1">
    <ng-container [ngTemplateOutlet]="placeholder1"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="PLACEHOLDER_TYPE.TYPE2">
    <ng-container [ngTemplateOutlet]="placeholder2"></ng-container>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <ng-container [ngTemplateOutlet]="default"></ng-container>
  </ng-container>
</ng-container>

<ng-template #placeholder1>
  <div class="placeholder placeholder__two-sided">
    <ng-container *ngIf="icon || image">
      <div class="placeholder__image">
        <img
          *ngIf="icon"
          [ngClass]="iconClassList"
          [defaultImage]="ICON_SHARED_URL + 'default.svg'"
          [lazyLoad]="ICON_BASE_URL + icon + '.svg'"
          alt="placeholder icon"
        />
        <img
          *ngIf="image"
          [ngClass]="iconClassList"
          [defaultImage]="ICON_SHARED_URL + 'default.svg'"
          [lazyLoad]="IMG_BASE_URL + image"
          alt="placeholder image"
        />
      </div>
    </ng-container>
    <ng-container *ngIf="title && subtitle">
      <div class="placeholder__text">
        <ui-text
          class="title"
          [ngClass]="titleClassList"
          [classList]="['placeholder-m', 'placeholder-height-s']"
          [text]="
            title && translateContext
              ? (title | translation : translateContext)
              : title
          "
        >
        </ui-text>

        <ui-text
          class="subtitle"
          [ngClass]="subtitleClassList"
          [classList]="['placeholder-m', 'placeholder-height-s']"
          [text]="
            subtitle && translateContext
              ? (subtitle | translation : translateContext)
              : subtitle
          "
        >
        </ui-text>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #placeholder2>
  <div class="placeholder placeholder__stacked">
    <ng-container *ngIf="icon || image">
      <div class="placeholder__image">
        <img
          *ngIf="icon"
          [ngClass]="iconClassList"
          [defaultImage]="ICON_SHARED_URL + 'default.svg'"
          [lazyLoad]="ICON_BASE_URL + icon + '.svg'"
          alt="placeholder icon"
        />
        <img
          *ngIf="image"
          [ngClass]="iconClassList"
          [defaultImage]="ICON_SHARED_URL + 'default.svg'"
          [lazyLoad]="IMG_BASE_URL + image"
          alt="placeholder image"
        />
      </div>
    </ng-container>
    <ng-container *ngIf="title || subtitle">
      <div class="placeholder__text">
        <ui-text
          class="title"
          [ngClass]="titleClassList"
          [classList]="['placeholder-m', 'placeholder-height-s']"
          [text]="
            title && translateContext
              ? (title | translation : translateContext)
              : title
          "
        >
        </ui-text>

        <ui-text
          class="subtitle"
          [ngClass]="subtitleClassList"
          [classList]="['placeholder-m', 'placeholder-height-s']"
          [text]="
            subtitle && translateContext
              ? (subtitle | translation : translateContext)
              : subtitle
          "
        >
        </ui-text>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #default>
  <!-- Add template here -->
</ng-template>
