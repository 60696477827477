export class storeMock {
  static getItem(store: object, key: string): string {
    return key in store ? store[key] : null;
  }

  static setItem(store: object, item: { key: string; value: string }) {
    store[item.key] = `${item.value}`;
  }

  static removeItem(store: object, key: string) {
    delete store[key];
  }

  static clear(store: object) {
    store = {};
  }
}

export const storageMock = function (storage) {
  return {
    setItem: function (key, value) {
      storage[key] = value || '';
    },
    getItem: function (key) {
      return key in storage ? storage[key] : null;
    },
    removeItem: function (key) {
      delete storage[key];
    },
    clear: function () {
      storage = {};
    },
    get length() {
      return Object.keys(storage).length;
    },
    key: function (i) {
      const keys = Object.keys(storage);
      return keys[i] || null;
    },
  };
};
