import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ITableCellConfig, TableCellType } from '@coyards/shared/models';
import { UiTextComponent } from '@coyards/shared/ui';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@Component({
  selector: 'ui-table-cell',
  standalone: true,
  imports: [
    CommonModule,
    LazyLoadImageModule,
    MatTooltipModule,
    UiTextComponent,
  ],
  templateUrl: './ui-table-cell.component.html',
  styleUrls: ['./ui-table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiTableCellComponent {
  @Input() cellConfig: ITableCellConfig;

  ICON_TABLE_URL = 'assets/icons/table/';
  ICON_SHARED_URL = 'assets/icons/shared/';

  TableCellType = TableCellType;
}
