import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { environment } from '@coyards/shared/environments';
import { TranslationPipe } from '@coyards/shared/pipes';
import {
  AuthGuard,
  RequestHelperService,
  ResponseHandlerService,
  TranslationsService,
} from '@coyards/shared/services';
import { UiToastComponent } from '@coyards/shared/ui';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PermissionGuard } from './guards/permission.guard';
import { MainFacade } from './modules/main/store';
import { MainEffects } from './modules/main/store/main.effects';
import * as fromMain from './modules/main/store/main.reducers';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreRouterConnectingModule.forRoot(),
    RouterModule.forRoot([]),
    StoreModule.forFeature(fromMain.MAIN_FEATURE_KEY, fromMain.mainReducer),
    EffectsModule.forFeature([MainEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    UiToastComponent,
    TranslationPipe,
  ],
  providers: [
    TranslationsService,
    RequestHelperService,
    ResponseHandlerService,
    MainFacade,
    AuthGuard,
    PermissionGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
