import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseResult } from '@coyards/shared/utilities';
import { Observable } from 'rxjs';
import { RequestHelperService } from './request-helper.service';
import { ResponseHandlerService } from './response-handler.service';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(
    private httpClient: HttpClient,
    private requestHelperService: RequestHelperService,
    private responseHandlerService: ResponseHandlerService
  ) {}

  saveImageFile(formData: FormData): Observable<ResponseResult<any>> {
    return this.responseHandlerService.defaultResponseProcessing(
      this.httpClient.put(
        this.requestHelperService.getUrl(`media/image`),
        formData,
        this.requestHelperService.getOptions({
          headers: { 'Content-Type': null },
        })
      )
    );
  }
}
