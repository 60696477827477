import { COMMA, ENTER, SEMICOLON, SPACE } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { IncludesPipe } from '@coyards/shared/pipes';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@Component({
  selector: 'ui-chips-input',
  standalone: true,
  imports: [
    CommonModule,
    MatChipsModule,
    MatInputModule,
    MatIconModule,
    LazyLoadImageModule,
    IncludesPipe,
  ],
  templateUrl: './ui-chips-input.component.html',
  styleUrls: ['./ui-chips-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UiChipsInputComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiChipsInputComponent implements ControlValueAccessor {
  @Input() list: string[] = [];
  @Input() placeholder: string;
  @Input() chipRemoveIcon = 'close-circle';
  @Input() highlightedChipIndexes: number[] = [];

  ICON_BASE_URL = 'assets/icons/btn/';
  ICON_SHARED_URL = 'assets/icons/shared/';

  separatorKeysCodes = [ENTER, COMMA, SPACE, SEMICOLON] as const;

  onChange = (value: string[]) => null;
  onTouched = () => null;

  writeValue(value: string[]): void {
    this.list = value;
  }

  registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  add(event: MatChipInputEvent): void {
    const inputValue = event.value?.trim();
    if (inputValue) {
      const newItems = this.parseEmails(inputValue);
      this.list.push(...newItems);
      this.updateValue();
    }
    // Clear the input value
    event.chipInput?.clear();
  }

  remove(item: string): void {
    const index = this.list.indexOf(item);
    if (index >= 0) this.list.splice(index, 1);
    this.updateValue();
  }

  private parseEmails(input: string): string[] {
    return input
      .split(/[\s,;]+/)
      .reduce((emails, email) => {
        const trimmedEmail = email.trim();
        if (trimmedEmail) emails.push(trimmedEmail);
        return emails;
      }, [] as string[]);
  }

  updateValue() {
    this.onChange(this.list);
  }
}
