import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IActivities } from '@coyards/shared/models';
import { TranslationPipe } from '@coyards/shared/pipes';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { UiDividerComponent } from '../ui-divider/ui-divider.component';
import { UiPresetTitleComponent } from '../ui-preset-title/ui-preset-title.component';

@Component({
  selector: 'ui-activities-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    LazyLoadImageModule,
    UiPresetTitleComponent,
    UiDividerComponent,
    TranslationPipe,
  ],
  templateUrl: './ui-activities-dashboard.component.html',
  styleUrls: ['./ui-activities-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiActivitiesDashboardComponent {
  ICON_SHARED_URL = 'assets/icons/shared/';
  ICON_ACTIVITIES_URL = 'assets/icons/activities/';

  @Input() title: string;
  @Input() activities: IActivities[];
  @Input() translateContext: string;
  @Input() titleClassList = ['text-m', 'text-margin-b-16']; //optional
  @Input() infoTitleClassList = ['text-l', 'text-height-32', 'text-margin-0']; //optional
  @Input() infoSubtitleClassList = [
    'text-xxs',
    'text-height-20',
    'text-weight-500',
  ]; //optional
}
