<div class="card">
  <div class="card__box flex fd-column j-space-between">
    <div class="flex j-space-between a-center">
      <ui-preset-title
        class="card__name card__pointer"
        [matTooltip]="data?.name?.length > 15 ? data?.name : ''"
        [title]="data?.name | truncate : [15]"
        [titleClassList]="nameClassList"
        (click)="titleClickEvent.emit()"
      >
      </ui-preset-title>
      <ui-dropdown
        [classList]="['btn', 'btn-center', 'btn-icon']"
        [optionClassList]="['option', 'option-left', 'option-xl']"
        [btnType]="'icon'"
        [btnIcon]="'nav_vert-more'"
        [menuOptions]="cardActions"
        [translateContext]="'groups_page'"
        (clickEvent)="onClickAction($event.name)"
      >
      </ui-dropdown>
    </div>

    <ui-preset-title
      class="card__created"
      [subtitle]="
        data?.created
          ? ('table_created_label' | translation : 'groups_page') +
            ' ' +
            (data?.created | date)
          : ''
      "
      [subtitleClassList]="registrationDateClassList"
    >
    </ui-preset-title>

    <ui-preset-title
      [matTooltip]="data?.description?.length > 40 ? data?.description : ''"
      [class.card__pointer]="data?.description?.length > 40"
      class="card__description"
      [para]="data?.description | truncate : [40]"
      [paraClassList]="descriptionClassList"
    >
    </ui-preset-title>

    <div class="card__info">
      <ui-preset-title
        class="card__info-item"
        [title]="membersTitle"
        [titleClassList]="infoTitleClassList"
        [subtitle]="data.groupMembersCount + ''"
        [subtitleClassList]="infoSubTitleClassList"
      >
      </ui-preset-title>
      <ui-preset-title
        class="card__info-item"
        [matTooltip]="data?.locationname?.length > 14 ? data?.locationname : ''"
        [class.card__pointer]="data?.locationname?.length > 14"
        [title]="locationTitle"
        [titleClassList]="infoTitleClassList"
        [subtitle]="data?.locationname | truncate : [14]"
        [subtitleClassList]="infoSubTitleClassList"
      >
      </ui-preset-title>
      <ui-preset-title
        class="card__info-item"
        [matTooltip]="
          (additionalInfoTypeTextId | translation : translationContext)
            ?.length > 14
            ? (additionalInfoTypeTextId | translation : translationContext)
            : ''
        "
        [class.card__pointer]="
          (additionalInfoTypeTextId | translation : translationContext)
            ?.length > 14
        "
        [title]="infoTitle"
        [titleClassList]="infoTitleClassList"
        [subtitle]="
          additionalInfoTypeTextId
            | translation : translationContext
            | truncate : [14]
        "
        [subtitleClassList]="infoSubTitleClassList"
      >
      </ui-preset-title>
    </div>
  </div>
</div>
