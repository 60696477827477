import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UiTextComponent, UiTooltipIconComponent } from '@coyards/shared/ui';

@Component({
  selector: 'ui-info-count',
  standalone: true,
  imports: [CommonModule, UiTextComponent, UiTooltipIconComponent],
  templateUrl: './ui-info-count.component.html',
  styleUrls: ['./ui-info-count.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiInfoCountComponent {
  @Input() infoCount: string | number;
  @Input() infoText: string;
  @Input() shapeClassList: string[];
  @Input() countClassList: string[];
  @Input() textClassList: string[];
  @Input() bgClassList: string[];
  @Input() tooltipIcon = 'tooltip_info.svg';
  @Input() tooltipText: string;
}
