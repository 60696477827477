import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includes',
  standalone: true,
})
export class IncludesPipe implements PipeTransform {
  transform(value: unknown[], searchValue: unknown): boolean {
    return Array.isArray(value) && value.includes(searchValue);
  }
}
