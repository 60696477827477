<mat-tab-group
  mat-align-tabs="center"
  [@.disabled]="true"
  [selectedIndex]="selected.value"
  (selectedIndexChange)="selected.setValue($event)"
  (selectedTabChange)="onTabChangeEvent($event)"
>
  <mat-tab-nav-panel #tabPanel>
    <mat-tab
      *ngFor="let tab of tabs; let index = index"
      [label]="
        tab?.titleId
          ? (tab.titleId | translation : translateContext)
          : tab?.title
      "
      [disabled]="tab?.disable"
    >
      <ng-container *ngComponentOutlet="tab.component"></ng-container>
    </mat-tab>
  </mat-tab-nav-panel>
</mat-tab-group>
