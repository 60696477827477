import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IStepChange, IStepperConfig } from '@coyards/shared/models';
import { TranslationPipe } from '@coyards/shared/pipes';
import { UiButtonComponent } from '../ui-button/ui-button.component';
import { UiPresetTitleComponent } from '../ui-preset-title/ui-preset-title.component';

@Component({
  selector: 'ui-stepper',
  standalone: true,
  imports: [
    CommonModule,
    CdkStepperModule,
    UiButtonComponent,
    UiPresetTitleComponent,
    TranslationPipe,
  ],
  templateUrl: './ui-stepper.component.html',
  styleUrls: ['./ui-stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: UiStepperComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiStepperComponent extends CdkStepper {
  initStepperConfig = {
    title: 'stepper_title',
    backBtnLabel: 'stepper_back_btn_label',
    nextBtnLabel: 'stepper_next_btn_label',
    lastBtnLabel: 'stepper_save_btn_label',
    translateContext: 'broadcast',
  };

  @Input() stepperConfig: IStepperConfig = this.initStepperConfig; //optional
  @Input() titleClassList = ['text-r']; //optional
  @Input() staticContentClassList = ['static-mb-32']; //optional
  @Input() subtitleClassList = ['text-xxs', 'text-margin-0']; //optional

  @Output() changeStepEvent = new EventEmitter<IStepChange>();

  onChangeStep(direction: 'previous' | 'next') {
    direction === 'previous' ? this.previous() : this.next();
    this.changeStepEvent.emit({
      stepIndex: this.selectedIndex,
      isNext: direction === 'next',
    });
  }
}
