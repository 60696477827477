<div class="checkbox" [classList]="classList">
  <mat-checkbox
    *ngIf="label || text || subtext"
    [color]="'accent'"
    [checked]="check"
    [indeterminate]="indeterminate"
    [disabled]="disable"
    [ngClass]="{ disable: disable }"
    (change)="onCheckControlChange($event)"
  >
    <span class="checkbox__content" [classList]="contentClassList">
      <ng-container *ngIf="label">
        <ui-text
          class="checkbox__label"
          [classList]="['placeholder-m', 'placeholder-height-s']"
          [matTooltip]="label.length > labelCharactersNumber ? label : ''"
          [style.width]="maxLabelWidth ? maxLabelWidth : ''"
          [style.cursor]="
            label.length > labelCharactersNumber ? 'pointer' : 'auto'
          "
          [text]="label | truncate : [labelCharactersNumber]"
        >
        </ui-text>
      </ng-container>

      <ng-container *ngIf="text">
        <ui-text
          class="checkbox__text"
          [classList]="['placeholder-m', 'placeholder-height-s']"
          [matTooltip]="text.length > textCharactersNumber ? text : ''"
          [style.cursor]="
            text.length > textCharactersNumber ? 'pointer' : 'auto'
          "
          [text]="text | truncate : [textCharactersNumber]"
        >
        </ui-text>
      </ng-container>

      <ng-container *ngIf="subtext">
        <ui-text
          class="checkbox__subtext"
          [classList]="['placeholder-m', 'placeholder-height-s']"
          [text]="subtext"
        >
        </ui-text>
      </ng-container>
    </span>
  </mat-checkbox>
</div>
