<span
  class="search"
  [style.color]="customColor"
  [style.background-color]="customBgColor"
>
  <label
    class="search__label"
    [ngClass]="[value || inputValue ? 'floated' : '']"
  >
    <ui-text
      [classList]="['placeholder-m', 'placeholder-height-s']"
      [text]="labelText"
    >
    </ui-text>
  </label>
  <span class="search__icon">
    <img
      width="20px"
      height="20px"
      [defaultImage]="ICON_SHARED_URL + 'default.svg'"
      [lazyLoad]="ICON_BASE_URL + iconName + '.svg'"
      alt="Input icon"
    />
  </span>
  <input
    matInput
    class="search__input"
    [ngClass]="{
      search__errorBorder: hasError
    }"
    type="text"
    autocorrect="off"
    autocapitalize="off"
    autocomplete="off"
    spellcheck="off"
    [ngClass]="classList"
    [formControl]="searchControl"
    [value]="value"
    (keyup)="keyUp()"
    (keyup.enter)="emitEnterEvent()"
  />

  <mat-error *ngIf="hasError && errorText" class="search__error">
    <ui-text
      [classList]="['placeholder-m', 'placeholder-height-s']"
      [text]="errorText"
    >
    </ui-text>
  </mat-error>
</span>
