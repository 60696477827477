<form
  class="select-single"
  [ngClass]="onlyIcon ? 'localized' : ''"
  [class.disable-tooltip]="disable"
>
  <div
    class="select-single__wrapper"
    [ngClass]="[isFloatLabel ? 'select-single__float-label' : '']"
    [matTooltip]="tooltipText"
    [matTooltipPosition]="tooltipPosition"
  >
    <label
      *ngIf="!onlyIcon"
      class="select-single__label"
      [ngClass]="[
        isFloatLabel && value !== null && value?.length !== 0 ? 'floated' : ''
      ]"
    >
      <ui-text
        [classList]="['placeholder-m', 'placeholder-height-s']"
        [text]="isFloatLabel ? labelText : inputLabel"
      >
      </ui-text>
    </label>
    <label
      class="select-single__all"
      *ngIf="isFloatLabel && (value === null || value?.length === 0)"
    >
      <ui-text
        [classList]="['placeholder-m', 'placeholder-height-s']"
        [text]="allLabel | translation : translateContext"
      >
      </ui-text>
    </label>

    <label *ngIf="!selectedItem" class="select-single__selectionLabel">
      <ui-text
        [classList]="['placeholder-m', 'placeholder-height-s']"
        [text]="selectionLabel"
      >
      </ui-text>
    </label>
    <img
      *ngIf="dropIcon"
      class="select-single__arrow"
      width="24px"
      height="24px"
      [defaultImage]="ICON_SHARED_URL + 'default.svg'"
      [lazyLoad]="ICON_BASE_URL + 'drop-arrow.svg'"
      [ngClass]="{ disable: disable }"
      alt="drop arrow icon"
      (click)="matSelect.toggle()"
    />

    <mat-select
      #matSelect
      class="select-single__trigger"
      disableOptionCentering
      [formControl]="selectControl"
      [panelClass]="[
        isMultiple ? 'selectPanel selectPanel--multi' : 'selectPanel'
      ]"
      [ngClass]="classList"
      [class.localized]="onlyIcon"
      [placeholder]="placeholder"
      [multiple]="isMultiple"
      (selectionChange)="emitSelectChange($event)"
    >
      <mat-select-trigger>
        <ng-container *ngIf="selectedItem">
          <img
            *ngIf="selectedItem?.icon"
            width="20px"
            class="select-single__icon"
            [defaultImage]="ICON_SHARED_URL + 'default.svg'"
            [lazyLoad]="FLAG_BASE_URL + selectedItem.icon"
            alt="menu icon"
          />
          {{
            onlyIcon
              ? ''
              : translateContext
              ? (selectedItem.textId | translation : translateContext)
              : (selectedItem.name || selectedItem?.Name
                | titlecase
                | truncate : [40])
          }}
          <span *ngIf="(selectControl.value?.length || 0) > 1">
            &nbsp;{{ selectedPostText }}
          </span>
        </ng-container>
      </mat-select-trigger>

      <mat-option
        *ngFor="let option of panelOptions"
        class="select-single__option"
        [ngClass]="optionClassList"
        [value]="option?.id"
        [disabled]="option?.disabled"
      >
        <img
          *ngIf="option?.icon"
          width="20px"
          class="select-single__icon"
          [defaultImage]="ICON_SHARED_URL + 'default.svg'"
          [lazyLoad]="FLAG_BASE_URL + option.icon"
          alt="menu icon"
        />
        {{
          translateContext && option?.textId
            ? (option?.textId | translation : translateContext)
            : (option?.name || option?.Name | titlecase)
        }}
      </mat-option>
    </mat-select>
  </div>

  <mat-error *ngIf="hasError && errorText" class="select-single__error">
    <ui-text
      [classList]="['placeholder-m', 'placeholder-height-s']"
      [text]="errorText"
    >
    </ui-text>
  </mat-error>
</form>
