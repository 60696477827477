<div [classList]="messageClassList">
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="MessageType.SUCCESS">
      <div class="message message--success">
        <ng-container [ngTemplateOutlet]="messageContent"></ng-container>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="MessageType.DANGER">
      <div class="message message--danger">
        <ng-container [ngTemplateOutlet]="messageContent"></ng-container>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="MessageType.WARN">
      <div class="message message--warn">
        <ng-container [ngTemplateOutlet]="messageContent"></ng-container>
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="message message--default">
        <ng-container [ngTemplateOutlet]="messageContent"></ng-container>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #messageContent>
    <div class="message__content">
      <img
        *ngIf="icon"
        class="message__icon"
        width="24px"
        height="24px"
        [defaultImage]="ICON_SHARED_URL + 'default.svg'"
        [lazyLoad]="ICON_BASE_URL + icon + '.svg'"
        alt="Message icon"
      />
      <ui-text
        class="message__text"
        [classList]="['placeholder-full', 'placeholder-height-s']"
        [text]="
          translateContext ? (text | translation : translateContext) : text
        "
      >
      </ui-text>
    </div>
  </ng-template>
</div>
