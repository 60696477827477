import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { ITabs } from '@coyards/shared/models';
import { TranslationPipe } from '@coyards/shared/pipes';

@Component({
  selector: 'ui-navbar-tabs',
  standalone: true,
  imports: [CommonModule, MatTabsModule, TranslationPipe],
  templateUrl: './ui-navbar-tabs.component.html',
  styleUrls: ['./ui-navbar-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiNavbarTabsComponent {
  @Input() tabs: ITabs[] = [];
  @Input() disable: boolean;
  @Input() translateContext: string;

  @Output() tabChangeEvent = new EventEmitter();

  selected = new FormControl(0);

  onTabChangeEvent(event: MatTabChangeEvent) {
    this.tabChangeEvent.emit(event);
  }
}
