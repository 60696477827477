import { Action, createReducer, on } from '@ngrx/store';
import * as mainActions from '.';
import { MainState } from './main.state';

export const MAIN_FEATURE_KEY = 'mainState';

export const mainReducer = createReducer(
  mainActions.initMainState,

  on(mainActions.setLoggedUser, (state, { loggedUser }): MainState => {
    return {
      ...state,
      loggedUser: loggedUser,
    };
  }),

  on(mainActions.setToast, (state, { data }): MainState => {
    return {
      ...state,
      toast: data,
    };
  }),

  // Reducer that clears the store when the user logout from the system
  on(mainActions.logoutAction, (): MainState => {
    return {
      ...mainActions.initMainState,
    };
  }),

  // Reducer that handle the breadcrumbs
  on(mainActions.setBreadcrumb, (state, { breadcrumb }): MainState => {
    return {
      ...state,
      breadcrumb,
    };
  })
);

export function reducer(state: MainState | undefined, action: Action) {
  return mainReducer(state, action);
}
