import { IHighlight } from '@coyards/shared/models';

export function searchFilter(
  search: string,
  list: any[],
  searchKey: string
): any[] {
  const searchValue = search?.toLowerCase();
  if (list && searchValue && searchValue !== '') {
    return list.filter(
      (item: any) =>
        item[searchKey] && item[searchKey].toLowerCase().includes(searchValue)
    );
  }
  return list;
}

export function generateHighlightObj(text, search): IHighlight {
  if (!text || !search) {
    return { nonHighlightedItems: [], highlightedItems: [] };
  }

  const regex = new RegExp(
    `(${search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`,
    'gi'
  );

  const highlightedItems = [];

  const nonHighlightedItems = text
    ?.replace(regex, (match) => {
      highlightedItems.push(match);
      return '*';
    })
    .split('*');
  return { nonHighlightedItems, highlightedItems };
}
