import { AbstractControl } from '@angular/forms';

export class ErrorHolder {
  constructor(private formGroup: any, private messages: any) {}

  private getControlByPath(name): AbstractControl {
    const params = name.split('.');
    let control = this.formGroup;
    params.forEach((path) => {
      control = control.controls[path];
    });
    return control;
  }

  hasErrors(name: string): boolean {
    const control = this.getControlByPath(name);
    return !!control.errors;
  }

  /**
   * name - string splited by . so for example
   *          "name" - name
   *          "role.name" -role.name
   *          "roles.1.name" - - roles[1].name
   */
  getErrors(name: string): any {
    const control = this.getControlByPath(name);
    if (!control?.errors) return;

    const errors = Object.keys(control.errors);
    const errorMessages = [];
    errors.forEach((error) => {
      errorMessages.push(this.getErrorByPath(name, error));
    });
    return errorMessages;
  }

  getErrorText(name: string): string {
    const errors = this.getErrors(name);
    return errors?.length ? errors[0] : '';
  }

  getHasError(controlName: string): boolean {
    return (
      this.formGroup?.get(controlName)?.invalid &&
      (this.formGroup?.get(controlName)?.dirty ||
        this.formGroup?.get(controlName)?.touched) &&
      this.hasErrors(controlName)
    );
  }

  private getErrorByPath(name: string, error: string) {
    let messages = this.messages;
    const paths = name.split('.');
    paths.forEach((path) => {
      messages = this.messages[path];
    });
    return messages[error] ? messages[error] : error;
  }

  setErrors(errors: any[]): void {
    Object.keys(errors).forEach((key: string) => {
      const field = key;
      const messages = errors[key];
      if (Array.isArray(messages)) {
        messages.forEach((message: string) => {
          this.setError(field, message);
        });
      } else {
        this.setError(field, messages);
      }
    });
  }

  setError(field: string, message: string): void {
    const control = this.getControlByPath(field);
    if (control) {
      const error = {};
      error[message] = true;
      control.setErrors(error);
      control.markAsTouched();
    }
  }
}
