import {
  AdditionalInfoData,
  GroupAdditionalInfoType,
  GroupAdditionalInfoTypeCategory,
} from '@coyards/shared/models';

export const groupInfoTypesByCategory = {
  [GroupAdditionalInfoTypeCategory.NeighbourhoodCooperation]: [
    GroupAdditionalInfoType.Address,
    GroupAdditionalInfoType.Title,
    GroupAdditionalInfoType.Flat,
    GroupAdditionalInfoType.Cottage,
    GroupAdditionalInfoType.House,
    GroupAdditionalInfoType.Location,
    GroupAdditionalInfoType.CampingSpace,
    GroupAdditionalInfoType.Allotment,
  ],
  [GroupAdditionalInfoTypeCategory.BoatCooperation]: [
    GroupAdditionalInfoType.Boatplace,
    GroupAdditionalInfoType.BoatDock,
    GroupAdditionalInfoType.BoatClub,
    GroupAdditionalInfoType.Marina,
  ],
  [GroupAdditionalInfoTypeCategory.CorporateCooperation]: [
    GroupAdditionalInfoType.ShopName,
    GroupAdditionalInfoType.CompanyName,
  ],
  [GroupAdditionalInfoTypeCategory.Other]: [
    GroupAdditionalInfoType.Association,
    GroupAdditionalInfoType.Organization,
  ],
};

export function getAdditionalInfoData(): AdditionalInfoData[] {
  const additionalInfoData = [];
  Object.keys(groupInfoTypesByCategory).forEach((category) => {
    const values = groupInfoTypesByCategory[category];

    values.forEach((additionalInfoType: GroupAdditionalInfoType) => {
      const infoTypeName = GroupAdditionalInfoType[additionalInfoType];
      const infoCategoryName = GroupAdditionalInfoTypeCategory[category];
      additionalInfoData.push({
        infoId: additionalInfoType,
        infoTypeName: `additionalInfoName_${infoTypeName}`,
        infoCategoryName: `additionalInfoCategoryName_${infoCategoryName}`,
      });
    });
  });
  return additionalInfoData;
}

export function getAdditionalInfoTypeTextId(
  additionalInfoType: GroupAdditionalInfoType
): string {
  if (additionalInfoType === undefined || additionalInfoType === null)
    return '';

  const infoTypeName = GroupAdditionalInfoType[additionalInfoType];
  return `additionalInfoName_${infoTypeName}`;
}
