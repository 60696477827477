import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { UiTextComponent } from '../ui-text/ui-text.component';
import { UiTooltipIconComponent } from '../ui-tooltip-icon/ui-tooltip-icon.component';

@Component({
  selector: 'ui-radio-button',
  standalone: true,
  imports: [
    CommonModule,
    MatRadioModule,
    UiTextComponent,
    UiTooltipIconComponent,
  ],
  templateUrl: './ui-radio-button.component.html',
  styleUrls: ['./ui-radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UiRadioButtonComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiRadioButtonComponent implements OnChanges, ControlValueAccessor {
  @Input() name: string;
  @Input() label: string; // For label's translations text
  @Input() labelData: string; // For label's with dynamic data
  @Input() subText: string; // optional subtext
  @Input() disable: boolean;
  @Input() check: boolean;
  @Input() inputValue: string; // For input value for selected data on initialize
  @Input() iconName: string;
  @Input() iconTooltipText: string;
  @Input() tooltipIconClassList: string[];
  @Input() radioButtonClass: string;
  @Input() radioButtonContentClassList = ['radio-btn__content'];
  @Input() radioButtonSubTextClassList = ['radio-btn__subtext'];

  @Output() changeEvent = new EventEmitter();

  /////////////////////////////////////////
  // Start of control value accessor assets
  value: any;
  disabled = false;
  onChange = (value: any) => null;
  onTouched = () => null;

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    if (value !== null && value !== undefined) {
      this.value = value;
      this.onChange(this.value);
      this.changeEvent.emit(this.value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  // End of control value accessor assets
  ///////////////////////////////////////

  ngOnChanges(changes: SimpleChanges): void {
    if ('inputValue' in changes) {
      if (this.inputValue !== null && this.inputValue !== undefined) {
        this.value = this.inputValue;
        this.onChange(this.value);
        this.changeEvent.emit(this.value);
      }
    }
  }
}
