<div class="chips-field">
  <mat-chip-list #chipList>
    <mat-chip
      *ngFor="let item of list; let i = index"
      (removed)="remove(item)"
      [class.highlight]="highlightedChipIndexes | includes : i"
    >
      {{ item }}
      <img
        matChipRemove
        class="chips-field__remove-icon"
        [defaultImage]="ICON_SHARED_URL + 'default.svg'"
        [lazyLoad]="ICON_BASE_URL + chipRemoveIcon + '.svg'"
        alt="btn icon"
      />
    </mat-chip>
    <input
      [placeholder]="placeholder"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="true"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-list>
</div>
