import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MAIN_FEATURE_KEY } from '.';
import { MainState } from './main.state';

export const selectMainFeatureState =
  createFeatureSelector<MainState>(MAIN_FEATURE_KEY);

export const selectLoggedUser = createSelector(
  selectMainFeatureState,
  (state: MainState) => state.loggedUser
);

export const selectAlertMessage = createSelector(
  selectMainFeatureState,
  (state: MainState) => state.alertMessage
);

export const selectToast = createSelector(
  selectMainFeatureState,
  (state: MainState) => state.toast
);

export const selectBreadcrumb = createSelector(
  selectMainFeatureState,
  (state: MainState) => state.breadcrumb
);
