import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MessageType } from '@coyards/shared/models';
import { TranslationPipe } from '@coyards/shared/pipes';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { UiTextComponent } from '../ui-text/ui-text.component';

@Component({
  selector: 'ui-preset-message',
  standalone: true,
  imports: [
    CommonModule,
    UiTextComponent,
    LazyLoadImageModule,
    TranslationPipe,
  ],
  templateUrl: './ui-preset-message.component.html',
  styleUrls: ['./ui-preset-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiPresetMessageComponent {
  @Input() type: string;
  @Input() icon: string;
  @Input() text: string;
  @Input() translateContext: string;
  @Input() messageClassList: string[];

  ICON_BASE_URL = 'assets/icons/message/';
  ICON_SHARED_URL = 'assets/icons/shared/';

  MessageType = MessageType;
}
