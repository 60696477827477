import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslationPipe } from '@coyards/shared/pipes';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { UiTextComponent } from '../ui-text/ui-text.component';

export enum PLACEHOLDER_TYPE {
  TYPE1 = 'type-1',
  TYPE2 = 'type-2',
  TYPE3 = 'type-3',
  TYPE4 = 'type-4',
}

@Component({
  selector: 'ui-placeholder-screen',
  standalone: true,
  imports: [
    CommonModule,
    UiTextComponent,
    TranslationPipe,
    LazyLoadImageModule,
  ],
  templateUrl: './ui-placeholder-screen.component.html',
  styleUrls: ['./ui-placeholder-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiPlaceholderScreenComponent {
  PLACEHOLDER_TYPE = PLACEHOLDER_TYPE;
  ICON_BASE_URL = 'assets/icons/placeholder/';
  ICON_SHARED_URL = 'assets/icons/shared/';
  IMG_BASE_URL = 'assets/images/placeholder/';

  @Input() titleClassList: string | string[]; // 'title-xs', 'title-s', 'title-primary'
  @Input() subtitleClassList: string | string[]; // 'subtitle-xs', 'subtitle-s', 'subtitle-primary'
  @Input() iconClassList: string | string[]; // 'icon__size-80', icon__size-200
  @Input() type: string; // 'type-1', 'type-2', 'type-3', 'type-4'
  @Input() title: string;
  @Input() subtitle: string;
  @Input() icon: string;
  @Input() image: string;
  @Input() translateContext: string;
}
