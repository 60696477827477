export enum MenuItemTypes {
  Groups = 'groups',
  Messages = 'messages',
  News = 'news',
  Checklists = 'checklists',
  Documents = 'documents',
  Muster = 'muster',
  GroupAlarm = 'group_alarm',
  OfflineDocuments = 'offline_documents',
  OrganizationWebsite = 'organization_website',
  Support = 'support',
  Settings = 'settings',
  Profile = 'profile',
  Alarm = 'alarm',
  OncallAlert = 'oncall_alert',
  GroupAlarmWorkAlone = 'group_alarm_work_alone',
}

export interface IMenuItem {
  id: number;
  technicalName: string;
}
