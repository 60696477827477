<div class="loader-content">
  <div class="content" [ngSwitch]="type">
    <span class="content__loader" *ngSwitchCase="loaderType.ROUTE">
      <img
        width="64px"
        height="64px"
        class="content__anim"
        [src]="ANIMATION_URL + 'spinner.svg'"
      />
    </span>

    <span class="content__loader" *ngSwitchCase="loaderType.DOWNLOAD">
      <img
        width="64px"
        height="64px"
        class="content__anim"
        [src]="ANIMATION_URL + 'spinner1.gif'"
      />
    </span>
  </div>
  <ui-text class="loader-content__title" [text]="'Loading...'"></ui-text>
</div>
