import { Injectable } from '@angular/core';
import { ReplaySubject, startWith } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  loaderSubject = new ReplaySubject<boolean>(1);
  loading$ = this.loaderSubject.asObservable().pipe(startWith(false));

  startLoading() {
    this.loaderSubject.next(true);
  }

  stopLoading() {
    this.loaderSubject.next(false);
  }
}
