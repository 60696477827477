/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';
import { TranslationPipe, TruncatePipe } from '@coyards/shared/pipes';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import first from 'lodash-es/first';
import pick from 'lodash-es/pick';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { Subject, takeUntil } from 'rxjs';
import { UiTextComponent } from '../ui-text/ui-text.component';

@Component({
  selector: 'ui-select',
  standalone: true,
  imports: [
    CommonModule,
    LazyLoadImageModule,
    MatTooltipModule,
    MatSelectModule,
    ReactiveFormsModule,
    UiTextComponent,
    TruncatePipe,
    TranslationPipe,
  ],
  templateUrl: './ui-select.component.html',
  styleUrls: ['./ui-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UiSelectComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiSelectComponent
  implements OnChanges, OnInit, OnDestroy, ControlValueAccessor
{
  @Input() classList: string | string[];
  @Input() optionClassList: string | string[];
  @Input() inputLabel: string;
  @Input() labelText: string;
  @Input() placeholder: string;
  @Input() selectionLabel: string;
  @Input() inputValue: number;
  @Input() allLabel: string; // optional
  @Input() hasError: boolean;
  @Input() errorText: string;
  @Input() panelOptions: any[] = []; // required
  @Input() selectedOptions: number[]; // if multiple true should pass integer array
  @Input() removeOptions: number[] = []; // index numbers
  @Input() subsetFields: string[] = [];
  @Input() isMultiple = false;
  @Input() disable: boolean;
  @Input() dropIcon = true; // optional
  @Input() translateContext: string;
  @Input() isFloatLabel = true;
  @Input() onlyIcon = false;
  @Input() optionsWithIdZero = false;
  @Input() tooltipText: string;
  @Input() tooltipPosition: TooltipPosition = 'below';

  @Output() clickEvent = new EventEmitter<any>();

  private ngUnsubscribe = new Subject();

  selectControl = new FormControl([]);
  selectedItem: any = null;
  selectedPostText: string = null;
  relavantselectionIds: number[] = [];

  ICON_BASE_URL = 'assets/icons/btn/';
  FLAG_BASE_URL = 'assets/images/flags/';
  ICON_SHARED_URL = 'assets/icons/shared/';

  /////////////////////////////////////////
  // Start of control value accessor assets
  value = null;
  disabled = false;
  onChange = (value: UiSelectComponent) => null;
  onTouched = () => null;

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    if (value !== null && value !== undefined) {
      this.value = value;
      this.selectControl.setValue(value);
    } else {
      this.selectControl.patchValue([]);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.selectControl.valueChanges.subscribe(fn(this.value));
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  // End of control value accessor assets
  ///////////////////////////////////////

  ngOnChanges(changes: SimpleChanges): void {
    if ('selectedOptions' in changes || 'panelOptions' in changes) {
      if (this.selectedOptions !== null && this.selectedOptions !== undefined) {
        setTimeout(() => {
          this.selectControl.setValue(this.selectedOptions);
          this.value = this.selectControl.value;
          this.onChange(this.value);
          this.clickEvent.emit(this.value);
        }, 0); // Slow down assigning values. To assign values after writeValue function
      } else {
        this.selectControl.patchValue([]);
      }
    }

    if ('panelOptions' in changes && this.panelOptions) {
      this.processPanelOptions();
      this.processFieldData();

      if (this.inputValue !== null && this.inputValue !== undefined) {
        this.setInputValue();
      }
    }

    if ('inputValue' in changes) {
      if (this.inputValue !== null && this.inputValue !== undefined) {
        setTimeout(() => {
          this.setInputValue();
        }, 1); // Slow down assigning values. To assign values after writeValue function
      } else {
        this.selectControl.patchValue([]);
      }
    }

    if ('disable' in changes) {
      this.disable ? this.selectControl.disable() : this.selectControl.enable();
    }
  }

  ngOnInit(): void {
    this.watchSelectChanges();
  }

  private processFieldData(
    selectedControlIds: number[] = this.selectControl.value
  ) {
    // There can be some ids not relevant to current panel options.
    const panelOptionIds = this.panelOptions.map((o) => o?.id);

    // Check if selectedControlIds is array (multi selection mode) or number
    this.relavantselectionIds = Array.isArray(selectedControlIds)
      ? filter(selectedControlIds, (id) => panelOptionIds.includes(id))
      : [selectedControlIds];

    this.selectedItem = find(
      this.panelOptions,
      (o) => o?.id === first(this.relavantselectionIds)
    );

    if (this.relavantselectionIds?.length >= 1) {
      let text =
        '(+' + (this.relavantselectionIds?.length - 1).toString() + ')';
      switch (this.relavantselectionIds?.length) {
        case 1:
          text = '';
          break;
        case 2:
          text = text + 'other';
          break;
        default:
          text = text + 'others';
      }
      this.selectedPostText = text;
    }
  }

  private setInputValue() {
    this.value = this.inputValue;
    this.selectControl.setValue(this.value);
    this.onChange(this.value);
    this.clickEvent.emit(this.value);
  }

  private processPanelOptions() {
    this.panelOptions = this.panelOptions
      .map((option) => {
        const subsetOption =
          this.subsetFields && this.subsetFields.length > 0
            ? pick(option, this.subsetFields)
            : option;
        return subsetOption;
      })
      .filter((option) => (!this.optionsWithIdZero ? option?.id !== 0 : true))
      .filter((o, i) => !this.removeOptions.includes(i));
  }

  private watchSelectChanges() {
    this.selectControl.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        const changedVal = typeof value === 'number' ? [value] : value;
        this.processFieldData(changedVal);
      });
  }

  emitSelectChange(event: MatSelectChange) {
    this.clickEvent.emit(event.value);
    this.selectControl.setValue(event.value);
    this.value = event.value;
    this.onChange(this.value);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
