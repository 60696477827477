<ng-container *ngIf="text !== '...'">
  {{ text }}
</ng-container>

<ng-container *ngIf="innerHtml">
  <span [innerHTML]="innerHtml"></span>
</ng-container>

<ng-container *ngIf="text === '...'">
  <span
    class="textline__placeholder"
    *ngFor="let line of expectedLines"
    [ngClass]="classList"
  >
    <div class="moving-rectangle"></div>
  </span>
</ng-container>
