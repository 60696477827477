import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslationPipe } from '@coyards/shared/pipes';
import { UiTextComponent } from '../ui-text/ui-text.component';

@Component({
  selector: 'ui-textarea',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    UiTextComponent,
    TranslationPipe,
  ],
  templateUrl: './ui-textarea.component.html',
  styleUrls: ['./ui-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UiTextareaComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiTextareaComponent implements OnChanges, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() placeholderTextId: string;
  @Input() inputValue: string | number;
  @Input() labelText: string;
  @Input() labelTextId: string;
  @Input() isFloatLabel = true;
  @Input() hasError: boolean;
  @Input() errorText: string;
  @Input() disable: boolean;
  @Input() classList: string[];
  @Input() maxLength: number; // optional
  @Input() minRows = 15; // min height of textarea (minimum 3)
  @Input() maxRows = 15; // max height of textarea
  @Input() maxLengthLabel = ' characters left'; // optional, can be replaced
  @Input() translateContext: string; // optional

  @Output() keyupEvent = new EventEmitter();

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  ICON_BASE_URL = 'assets/icons/field/';
  ICON_SHARED_URL = 'assets/icons/shared/';

  textFormControl = new FormControl();

  leftLength = 0;

  /////////////////////////////////////////
  // Start of control value accessor assets
  value;
  disabled = false;
  onChange = (value: any) => null;
  onTouched = () => null;

  writeValue(value: any): void {
    if (value !== null && value !== undefined) {
      this.value = value;
      this.textFormControl.setValue(value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.textFormControl.valueChanges.subscribe(fn(this.value));
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  // End of control value accessor assets
  ///////////////////////////////////////

  ngOnChanges(changes: SimpleChanges): void {
    if ('inputValue' in changes) {
      if (this.inputValue !== null && this.inputValue !== undefined) {
        this.textFormControl.setValue(this.inputValue);
        this.value = this.textFormControl.value;
        if (this.maxLength) {
          this.leftLength = this.maxLength - this.value.length;
        }
        this.onChange(this.value);
        this.keyupEvent.emit(this.value);
      }
    }

    if ('disable' in changes) {
      changes['disable'].currentValue
        ? this.textFormControl.disable()
        : this.textFormControl.enable();
    }
  }

  private updateValue() {
    this.value = this.textFormControl.value;
    if (this.maxLength) {
      this.leftLength = this.maxLength - this.value.length;
    }
    this.keyupEvent.emit(this.value);
    this.onChange(this.value);
  }

  keyUp() {
    this.updateValue();
  }
}
