import { Pipe, PipeTransform } from '@angular/core';
import {
  ILanguage,
  ITableCellConfig,
  ITableColumnConfig,
  TableCellType,
} from '@coyards/shared/models';
import { generateHighlightObj } from '@coyards/shared/utilities';

@Pipe({
  name: 'tableCellConstructor',
  standalone: true,
})
export class TableCellConstructorPipe implements PipeTransform {
  transform(
    column: ITableColumnConfig,
    row: unknown,
    tableComponent: any,
    triggerUpdate: object
  ): ITableCellConfig {
    const config: ITableCellConfig = {
      type: column.type,
      ...(column.cell && {
        text: column.cell(row, tableComponent),
      }),
      ...(column.dataClass && {
        dataClass: column.dataClass(row, tableComponent),
      }),
      ...(column.cellToolTip && {
        textTooltip: column.skipTooltipTranslate
          ? column.cellToolTip(row, tableComponent)
          : column.cellToolTip(row, tableComponent)
          ? tableComponent.translationsService.translate(
              column.cellToolTip(row, tableComponent),
              column.translationContext
            )
          : '',
      }),
      ...(column.type === TableCellType.HIGHLIGHT && {
        highlight: generateHighlightObj(
          column.cell(row, tableComponent),
          tableComponent.search
        ),
      }),
      ...(column.cellIcon && {
        icon: column.cellIcon(row, tableComponent),
      }),
      ...(column.imageClass && {
        imageClass: column.imageClass,
      }),
      ...(column.cellIconToolTip && {
        iconTooltip: column.cellIconToolTip(row, tableComponent),
      }),
      ...(column.iconBeforeText && {
        iconBeforeText: column.iconBeforeText(row, tableComponent),
      }),
      ...(column.iconBeforeTextToolTip && {
        iconBeforeTextToolTip: column.iconBeforeTextToolTip(row, tableComponent),
      }),
    };
    return config;
  }
}
