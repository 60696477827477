import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ResponseResult } from '@coyards/shared/utilities';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ResponseHandlerService {
  constructor(private router: Router) {}

  /**
   * Validate is success response or not
   * Add Type??
   */
  validateResponse<T>(response: any): ResponseResult<T> {
    return this.handleResponse(response);
  }

  handleResponse<T>(
    response: HttpResponse<T> | HttpErrorResponse
  ): ResponseResult<T> {
    let data = null;
    let errors: any = null;

    if (response.headers.get('RequestVerificationToken')) {
      sessionStorage.setItem(
        'rvt',
        response.headers.get('RequestVerificationToken')
      );
    }

    switch (response.status) {
      case 200:
        data = (response as HttpResponse<T>).body;
        break;
      case 404:
      case 409:
      case 403:
        errors = (response as HttpErrorResponse).error;
        break;
      case 401:
        errors = (response as HttpErrorResponse).error;
        if (
          errors &&
          Object.prototype.hasOwnProperty.call(errors, 'Code') &&
          errors.Code === 'reenter-password'
        ) {
          console.log('redirect', errors);
          this.router.navigate(['/login']);
          break;
        }
        if (!(errors && errors.message)) {
          errors = { message: `app_common_error_${response.status}` };
        }
        break;
      case 400:
        errors = (response as HttpErrorResponse).error;
        break;
      case 422:
        errors = (response as HttpErrorResponse).error.errors;
        break;
      case 500:
        errors = (response as HttpErrorResponse).error;
        break;
      default:
        break;
    }

    return new ResponseResult(data, errors);
  }

  defaultResponseProcessing<T>(
    request: Observable<HttpResponse<T> | HttpErrorResponse>
  ) {
    return request.pipe(
      map((response: HttpResponse<T>) => {
        const result = this.validateResponse<T>(response);
        return result;
      }),
      catchError((response: HttpErrorResponse) => {
        const result = this.validateResponse<T>(response);
        return of(result);
      })
    );
  }
}
