import { ThemePalette } from '@angular/material/core';

export interface ICustomDialogConfig {
  cancelBtnColor?: ThemePalette;
  cancelBtnLabel?: string;
  okBtnColor?: ThemePalette;
  okBtnType?: BtnType;
  okBtnLabel?: string;
  dialogMsg?: string;
  dialogRedMsg?: string;
  dialogRedMsgParam?: { [key: string]: string };
  dialogMsgParam?: { [key: string]: string };
  dialogTitle?: string;
  dialogType?: 'alert' | 'confirm';
  isClickedOk?: boolean;
  icon?: string;
  iconType?: 'warning' | 'error' | 'success';
  confirmationWord?: string;
  translationsContext?: string;
  keyWord?: string;
  messageText?: string;
}
export interface ICustomDataDialogConfig {
  cancelBtnColor?: ThemePalette;
  cancelBtnLabel?: string;
  okBtnColor?: ThemePalette;
  okBtnLabel?: string;
  dialogMsg?: string;
  dialogMsgParam?: { [key: string]: string };
  dialogTitle?: string;
  dialogTitleParam?: { [key: string]: string };
  icon?: string;
  iconType?: 'warning' | 'error' | 'success';
  translationsContext?: string;
  supportData?: any; // Use this to insert any other supoort data
  disableClose?: boolean; // Whether the user can use escape or clicking on the backdrop to close the modal
  width?: string;
}

export type BtnType = 'accent' | 'warn';
