<span *ngIf="infoCount !== null" class="info-content" [ngClass]="bgClassList">
  <ui-text
    *ngIf="shapeClassList"
    class="info__shape"
    [ngClass]="shapeClassList"
    [classList]="['placeholder-m', 'placeholder-height-s']"
  >
  </ui-text>
  <ui-text
    class="info__count"
    [ngClass]="countClassList"
    [classList]="['placeholder-m', 'placeholder-height-s']"
    [text]="'' + infoCount"
  >
  </ui-text>
  <ui-text
    class="info__text"
    [ngClass]="textClassList"
    [classList]="['placeholder-m', 'placeholder-height-s']"
    [text]="infoText"
  >
  </ui-text>

  <ui-tooltip-icon
    *ngIf="tooltipText"
    class="info__tooltip"
    [classList]="['icon', 'icon-xxxm']"
    [iconName]="tooltipIcon"
    [iconTooltipText]="tooltipText"
  >
  </ui-tooltip-icon>
</span>
