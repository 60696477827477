import { Pipe, PipeTransform } from '@angular/core';
import {
  IDropOption,
  ITableColumnConfig,
  TableActionsEnum,
} from '@coyards/shared/models';

@Pipe({
  name: 'action',
  standalone: true,
})
export class ActionPipe implements PipeTransform {
  transform(
    name: TableActionsEnum,
    action: IDropOption,
    rowData: unknown,
    columnConfig?: ITableColumnConfig,
    compRef?: any
  ) {
    switch (name) {
      case TableActionsEnum.DISABLE:
        return action?.disable(rowData);
      case TableActionsEnum.TOOLTIP_TEXT:
        return columnConfig?.cellToolTip
          ? columnConfig?.cellToolTip(rowData, compRef)
          : '';
    }
  }
}
