<span class="field" [ngClass]="{ disable: disable }">
  <textarea
    matInput
    cdkTextareaAutosize
    #autosize="cdkTextareaAutosize"
    [cdkAutosizeMinRows]="minRows"
    [cdkAutosizeMaxRows]="maxRows"
    type="text"
    class="field__input"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="off"
    [maxlength]="maxLength"
    [ngClass]="classList"
    [formControl]="textFormControl"
    [placeholder]="
      isFloatLabel
        ? null
        : translateContext && placeholderTextId
        ? (placeholderTextId | translation : translateContext)
        : placeholder
    "
    [value]="value"
    (keyup)="keyUp()"
  ></textarea>

  <label
    *ngIf="isFloatLabel"
    class="field__label"
    [ngClass]="[
      value === null || value === undefined || value === '' ? '' : 'floated'
    ]"
  >
    <ui-text
      [classList]="['placeholder-m', 'placeholder-height-s']"
      [text]="labelText"
    >
    </ui-text>
  </label>

  <label *ngIf="maxLength" class="field__characters">
    <ui-text
      [classList]="['placeholder-m', 'placeholder-height-s']"
      [text]="leftLength + maxLengthLabel"
    >
    </ui-text>
  </label>

  <mat-error *ngIf="hasError && errorText" class="field__error">
    <ui-text
      [classList]="['placeholder-m', 'placeholder-height-s']"
      [text]="errorText"
    >
    </ui-text>
  </mat-error>
</span>
