import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import pick from 'lodash-es/pick';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { Observable, map, startWith } from 'rxjs';
import { UiDividerComponent } from '../ui-divider/ui-divider.component';
import { UiTextComponent } from '../ui-text/ui-text.component';

@Component({
  selector: 'ui-autocomplete',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    LazyLoadImageModule,
    UiDividerComponent,
    UiTextComponent,
  ],
  templateUrl: './ui-autocomplete.component.html',
  styleUrls: ['./ui-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UiAutocompleteComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiAutocompleteComponent
  implements OnChanges, OnInit, ControlValueAccessor
{
  @Input() inputLabel: string;
  @Input() labelText: string;
  @Input() panelOptions: any[]; // required
  @Input() injectPanelOptions: unknown[] = [];
  @Input() selectedOption: any;
  @Input() optionKey = 'name'; // required: visible option name field
  @Input() optionId = 'id'; // required: identitity field name of the option
  @Input() sortField = 'id'; // optional: field name for sorting options
  @Input() concatField: string; // optional
  @Input() subsetFields: string[]; // optional
  @Input() classList: string[];
  @Input() isFloatLabel = false;
  @Input() disable: boolean;
  @Input() cleanIcon = false;

  @Output() clickEvent = new EventEmitter();
  @Output() enterEvent = new EventEmitter();

  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  ICON_BASE_URL = 'assets/icons/btn/';
  ICON_SHARED_URL = 'assets/icons/shared/';

  searchControl = new FormControl();
  filteredOptions$: Observable<any[]>;
  isFocused = false;
  lastSelectedOption: any;

  /////////////////////////////////////////
  // Start of control value accessor assets
  value: UiAutocompleteComponent;
  disabled = false;
  onChange = (value: string) => null;
  onTouched = () => null;

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: string): void {
    if (value !== null && value !== undefined) {
      this.searchControl.setValue(Object(value)); // Updates component's control when parent control changes
      this.value = Object(value)[this.optionKey];
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    // this.searchControl.valueChanges.subscribe(fn(this.value))
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // End of control value accessor assets
  ///////////////////////////////////////

  ngOnChanges(changes: SimpleChanges): void {
    if ('panelOptions' in changes) {
      this.panelOptions = this.panelOptions
        ? [...this.injectPanelOptions, ...this.panelOptions]
        : [...this.injectPanelOptions];
      this.initializeData();
      this.watchDataChanges();
    }
    if ('selectedOption' in changes) {
      if (this.selectedOption !== null && this.selectedOption !== undefined) {
        const selected = this.panelOptions.find(
          (o) => o[this.optionId] === this.selectedOption[this.optionId]
        );
        this.searchControl.setValue(selected);
      }
    }
    if ('disable' in changes) {
      changes['disable'].currentValue
        ? this.searchControl.disable()
        : this.searchControl.enable();
    }
  }

  ngOnInit(): void {
    this.disable ? this.searchControl.disable() : null;
  }

  private initializeData() {
    this.panelOptions = this.panelOptions
      .map((option) => {
        const concatOption = { ...option };
        if (
          this.concatField &&
          Object.prototype.hasOwnProperty.call(concatOption, this.concatField)
        ) {
          concatOption[this.optionKey] =
            concatOption[this.optionKey] +
            ' (' +
            concatOption[this.concatField] +
            ')';
        }
        return concatOption;
      })
      .map((option) => {
        const subsetOption =
          this.subsetFields && this.subsetFields.length > 0
            ? pick(option, this.subsetFields)
            : option;
        return subsetOption;
      })
      .sort((option1, option2) => {
        if (this.optionKey && this.sortField) {
          return option1[this.sortField] < option2[this.sortField] ? -1 : 1;
        } else {
          return option1.toLowerCase() < option2.toLowerCase() ? -1 : 1;
        }
      });

    // set the same value when language was changed
    if (this.searchControl.value) {
      this.searchControl.setValue(
        this.panelOptions.find(
          (o) => o[this.optionId] === this.searchControl.value[this.optionId]
        )
      );
    }
  }

  private watchDataChanges() {
    this.filteredOptions$ = this.searchControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterOptions(value))
    );
  }

  private _filterOptions(value: any): any[] {
    if (!value) {
      return this.panelOptions;
    }
    const filterValue =
      typeof value === 'object'
        ? value[this.optionKey].toLowerCase()
        : value.toLowerCase();
    const filteredOptions = this.panelOptions.filter((option) => {
      return option[this.optionKey]?.toLowerCase().includes(filterValue);
    });
    return filteredOptions.length
      ? filteredOptions
      : [
          {
            [this.optionId]: null,
            [this.optionKey]: 'No data found, please try again...',
          },
        ];
  }

  public allowSelection(option: string): { [className: string]: boolean } {
    return {
      'no-data': option === 'No data found, please try again...',
    };
  }

  public openPanel(event: Event): void {
    event.stopPropagation();
    this.autocomplete.openPanel();
  }

  private emitSelection(data: any) {
    this.clickEvent.emit(data); // For the event emitter
    this.onChange(data); // For the form control
  }

  public displayWith(option: any) {
    return this.optionKey && option ? option[this.optionKey] : '';
  }

  public onSelectEvent(event: MatAutocompleteSelectedEvent) {
    const selectedId = event.option.value[this.optionId];
    if (this.lastSelectedOption !== event.option.value[this.optionKey]) {
      const selectedOption = this.panelOptions.find((option) =>
        this.optionKey
          ? option[this.optionId] === selectedId
          : option === selectedId
      );
      this.searchControl.setValue(selectedOption);
      this.emitSelection(selectedOption);
      this.lastSelectedOption = event.option.value[this.optionKey];
    }
  }

  public keyUp(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.enterEvent.emit();
    }
  }

  cleanField(event: Event) {
    event.stopPropagation();
    this.autocomplete.openPanel();
    this.searchControl.setValue('');
  }
}
