import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  AccountInfo,
  AlertMessage,
  IBreadcrumb,
  IToast,
} from '@coyards/shared/models';
import { Observable } from 'rxjs';
import * as MainActions from './main.actions';
import * as MainSelectors from './main.selectors';

@Injectable({
  providedIn: 'root',
})
export class MainFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  toast$: Observable<IToast>;
  alertMessage$: Observable<AlertMessage>;
  loggedUser$: Observable<AccountInfo>;
  breadcrumb$: Observable<IBreadcrumb>;

  constructor(private readonly store: Store) {
    this.toast$ = this.store.select(MainSelectors.selectToast);
    this.alertMessage$ = this.store.select(MainSelectors.selectAlertMessage);
    this.loggedUser$ = this.store.select(MainSelectors.selectLoggedUser);
    this.breadcrumb$ = this.store.select(MainSelectors.selectBreadcrumb);
  }

  setLoggedUser(loggedUser: AccountInfo) {
    this.store.dispatch(MainActions.setLoggedUser({ loggedUser }));
  }

  setBreadcrumb(breadcrumb: IBreadcrumb) {
    this.store.dispatch(MainActions.setBreadcrumb({ breadcrumb }));
  }

  showToast(toast: IToast) {
    this.store.dispatch(MainActions.setToast({ data: toast }));
  }
}
