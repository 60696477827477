import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { TranslationsService } from './translations.service';

@Injectable({
  providedIn: 'any',
})
export class TimestampfilterService {
  private val = '';

  constructor(private translationService: TranslationsService) {}

  private isUtc(ts: string) {
    return !/([zZ]|[+-]\d{2}:\d{2})$/.test(ts);
  }

  transform(ts: string): string {
    const lang =
      this.translationService.getCurrentLanguage() ||
      this.translationService.getDefaultLanguage();

    if (ts && lang) {
      const timeMoment = this.isUtc(ts) ? moment.utc(ts).local() : moment(ts);
      const today = moment().startOf('day');
      const daysFromToday = today.diff(
        moment(timeMoment).startOf('day'),
        'days'
      );

      if (daysFromToday === 0) {
        this.val = this.translationService.translate(
          'today_date_text',
          'user_page',
          lang,
          { date: timeMoment.format('HH:mm') }
        );
      } else if (daysFromToday === 1) {
        this.val = this.translationService.translate(
          'yesterday_date_text',
          'user_page',
          lang,
          { date: timeMoment.format('HH:mm') }
        );
      } else if (daysFromToday < 7) {
        this.val = timeMoment.format('YYYY-MM-DD HH:mm');
      } else {
        this.val = timeMoment.format('YYYY-MM-DD');
      }
    }
    return this.val;
  }
}
