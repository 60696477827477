import { AbstractControl } from '@angular/forms';

export const passwordRegex = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(?=.{10,})/
);

export const fullPhoneNumberPattern = new RegExp(
  /^\+?[\d\-\s()]+,?\+?[\d\-\s()]*$/
);

export const numberOfUsersPattern = new RegExp('^[0-9]+$');

export const numberRegExp = new RegExp(/^[0-9-]*$/);

export const notNumberRegExp = new RegExp(/[^0-9]/g);

export const emailRexExp = new RegExp(
  /^([\w.\-+]+)@([\w-]+(\.[\w-]+)*\.(\w{2,20}))$/
);

export const urlRegExp = new RegExp(
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
);

export const imageExtensionsRegex = new RegExp(/^.*\.(png|jpg|jpeg)$/i);

export function passwordMatchValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {
  return control.get('passwordNew').value ===
    control.get('passwordConfirm').value
    ? null
    : { mismatch: true };
}
