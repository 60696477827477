export interface ILevelConfig {
  label: string;
  value: string;
  icon?: string;
  checkbox?: boolean;
  isCheckboxProperty?: string;
  child?: string;
  selected?: any[];
  selectedProperty?: string;
}
