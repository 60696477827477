export interface IPagination {
  skip?: number;
  limit: number;
}

export interface ITablePagination {
  pageIndex: number;
  pageSize: number;
  previousPageIndex: number;
  length: number | null;
}
