import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatSlideToggleChange,
  MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';
import { UiTextComponent } from '../ui-text/ui-text.component';

@Component({
  selector: 'ui-toggle-slide',
  standalone: true,
  imports: [
    CommonModule,
    MatSlideToggleModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    UiTextComponent,
  ],
  templateUrl: './ui-toggle-slide.component.html',
  styleUrls: ['./ui-toggle-slide.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UiToggleSlideComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiToggleSlideComponent implements OnChanges, ControlValueAccessor {
  @Input() label: string;
  @Input() slide: boolean;
  @Input() classList: string[]; // ['label-type-1']
  @Input() disable: boolean;
  @Input() labelBefore: boolean;
  @Input() tooltipText: string;
  @Input() tooltipPosition: TooltipPosition = 'below';

  @Output() toggleEvent = new EventEmitter<boolean>();

  toggleControl = new FormControl();

  /////////////////////////////////////////
  // Start of control value accessor assets
  value;
  disabled = false;
  onChange = (value: any) => null;
  onTouched = () => null;

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    if (value !== null && value !== undefined) {
      this.value = value;
      this.toggleControl.setValue(value);
      this.toggleEvent.emit(this.value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.toggleControl.valueChanges.subscribe(fn(this.value));
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  // End of control value accessor assets
  ///////////////////////////////////////

  ngOnChanges(changes: SimpleChanges): void {
    if ('slide' in changes) {
      if (this.slide !== null && this.slide !== undefined) {
        this.value = this.slide;
        this.toggleControl.setValue(this.slide);
        this.onChange(this.value);
      }
    }

    if ('disable' in changes) {
      changes['disable'].currentValue
        ? this.toggleControl.disable()
        : this.toggleControl.enable();
    }
  }

  onCheckToggleChange(changed: MatSlideToggleChange) {
    this.value = changed.checked;
    this.onChange(this.value);
    this.toggleEvent.emit(this.value);
  }
}
