import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'ui-divider',
  standalone: true,
  imports: [CommonModule, MatDividerModule],
  templateUrl: './ui-divider.component.html',
  styleUrls: ['./ui-divider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiDividerComponent {
  @Input() classList: string[];
  @Input() horizontal: boolean;
  @Input() height: number; // height of vertical divider in px
}
