export interface IContact {
  name: string;
  email: string;
  phoneNumber: string;
  created: string;
}

export interface IDetails {
  id?: number;
  name: string;
  description: string;
  locationname: string;
  additionalInfoType: number;
  created: string;
  groupMembersCount: number;
}

export type IActionType = 'delete' | 'edit';

export enum ActionColors {
  RED = 'red',
}
