import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ILanguageText } from '@coyards/shared/models';
import { TruncatePipe } from '@coyards/shared/pipes';
import { UiTextComponent } from '../ui-text/ui-text.component';

@Component({
  selector: 'ui-checkbox',
  standalone: true,
  imports: [
    CommonModule,
    UiTextComponent,
    MatCheckboxModule,
    TruncatePipe,
    MatTooltipModule,
  ],
  templateUrl: './ui-checkbox.component.html',
  styleUrls: ['./ui-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UiCheckboxComponent,
    },
  ],
})
export class UiCheckboxComponent implements OnChanges, ControlValueAccessor {
  @Input() name: ILanguageText;
  @Input() label: string;
  @Input() text: string;
  @Input() subtext: string;
  @Input() disable: boolean;
  @Input() check: boolean;
  @Input() indeterminate: boolean;
  @Input() classList: string[]; //checkbox__square
  @Input() contentClassList: string[];
  @Input() maxLabelWidth: string;
  @Input() labelCharactersNumber: number;
  @Input() textCharactersNumber: number;

  @Output() checkEvent = new EventEmitter();

  /////////////////////////////////////////
  // Start of control value accessor assets
  value;
  disabled = false;
  onChange = (value: any) => null;
  onTouched = () => null;

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    if (value !== null && value !== undefined) {
      this.value = value;
      this.check = value;
      this.checkEvent.emit(this.value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  // End of control value accessor assets
  ///////////////////////////////////////

  ngOnChanges(changes: SimpleChanges): void {
    if ('check' in changes) {
      if (this.check !== null && this.check !== undefined) {
        this.value = this.check;
        this.onChange(this.value);
        this.checkEvent.emit(this.value);
      }
    }

    if ('text' in changes) {
      if (Array.isArray(this.text)) {
        this.text = this.text.join(', ');
      }
    }
  }

  onCheckControlChange(event: MatCheckboxChange) {
    this.value = event.checked;
    this.check = event.checked;
    this.onChange(this.value);
    this.checkEvent.emit(this.value);
  }
}
