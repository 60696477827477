export interface IMunicipality {
  id: number;
  name: string;
  county?: string;
}

export interface ICounty {
  id: number;
  name: string;
}
