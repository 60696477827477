import { Type } from '@angular/core';

export interface ITablink {
  name: string;
  text: string;
  route: string;
  disable: boolean;
  icon?: string;
  textId?: string;
  tooltip?: string;
  permission?: boolean;
}

export interface ITabs {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: Type<any>;
  title?: string;
  titleId?: string;
  disable?: boolean;
}
