<div class="navbar__tabs">
  <nav
    mat-tab-nav-bar
    [color]="'accent'"
    [tabPanel]="tabPanel"
    [attr.mat-align-tabs]="tabsAlign"
  >
    <a
      mat-tab-link
      *ngFor="let link of tabLinks"
      #rla="routerLinkActive"
      routerLinkActive
      [routerLink]="link?.disable ? null : '.' + link.route"
      [routerLinkActiveOptions]="{ exact: true }"
      [active]="rla.isActive"
      [matTooltip]="
        link?.tooltip ? (link.tooltip | translation : translateContext) : ''
      "
      [matTooltipPosition]="'below'"
      [ngClass]="{ 'disable-tooltip': link?.disable }"
      (click)="onClickEvent(link)"
    >
      <ui-text
        [class.active-tab]="rla.isActive"
        [classList]="['placeholder-m', 'placeholder-height-s']"
        [text]="
          translateContext
            ? (link?.text | translation : translateContext)
            : link?.text
        "
      >
      </ui-text>
    </a>
  </nav>

  <mat-tab-nav-panel #tabPanel>
    <ng-content select="[tabPanel]"></ng-content>
  </mat-tab-nav-panel>
</div>
