import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import {
  Subject,
  debounceTime,
  distinctUntilChanged,
  skip,
  takeUntil,
  tap,
} from 'rxjs';
import { UiTextComponent } from '../ui-text/ui-text.component';

@Component({
  selector: 'ui-search',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    MatFormFieldModule,
    MatInputModule,
    UiTextComponent,
  ],
  templateUrl: './ui-search.component.html',
  styleUrls: ['./ui-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UiSearchComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiSearchComponent
  implements OnInit, OnChanges, OnDestroy, ControlValueAccessor
{
  @Input() labelText: string;
  @Input() labelTextId: string;
  @Input() hasError: boolean;
  @Input() errorText: string;
  @Input() inputValue: string;
  @Input() iconName: string;
  @Input() disable: boolean;
  @Input() classList: string[];
  @Input() customBgColor: string;
  @Input() customColor: string;

  @Output() valueChangeEvent = new EventEmitter<string>();
  @Output() keyupEnterEvent = new EventEmitter<string>();

  private ngUnsubscribe = new Subject();

  ICON_BASE_URL = 'assets/icons/field/';
  ICON_SHARED_URL = 'assets/icons/shared/';

  searchControl = new FormControl();

  /////////////////////////////////////////
  // Start of control value accessor assets
  value;
  disabled = false;
  onChange = (value: any) => null;
  onTouched = () => null;

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
    this.searchControl.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.searchControl.valueChanges.subscribe(fn(this.value));
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // End of control value accessor assets
  ///////////////////////////////////////

  ngOnChanges(changes: SimpleChanges): void {
    if ('disable' in changes) {
      changes['disable'].currentValue
        ? this.searchControl.disable()
        : this.searchControl.enable();
    }

    if ('inputValue' in changes) {
      if (
        this.inputValue !== null &&
        this.inputValue !== undefined &&
        this.inputValue !== ''
      ) {
        setTimeout(() => {
          this.searchControl.setValue(this.inputValue);
          this.updateValue();
        }, 0); // Slow down assigning values. To assign values after writeValue function
      }
    }
  }

  ngOnInit() {
    this.searchControl.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        skip(1),
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.emitChangeEvent())
      )
      .subscribe();
  }

  private updateValue() {
    this.value = this.searchControl.value;
    this.onChange(this.value);
  }

  keyUp() {
    this.updateValue();
  }

  emitChangeEvent() {
    this.valueChangeEvent.emit(this.searchControl.value);
  }

  emitEnterEvent() {
    const controlValue = this.searchControl.value;
    this.keyupEnterEvent.emit(controlValue);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
