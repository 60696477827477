import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@coyards/shared/environments';

@Injectable()
export class RequestHelperService {
  private baseUrl = environment.apiUrl;

  public getUrl(url: string) {
    return this.baseUrl + url;
  }

  public buildParameters(params: { [key: string]: any }): HttpParams {
    return Object.entries(params).reduce((httpParams, key) => {
      return key[1] == null || key[1] === ''
        ? httpParams
        : httpParams.append(key[0], key[1]);
    }, new HttpParams());
  }

  public getOptions(
    options: { headers?: any; params?: any; body?: any } = {
      headers: { 'Content-Type': 'application/json' },
    }
  ): {
    headers?:
      | HttpHeaders
      | {
          [header: string]: string | string[];
        };
    observe: 'response';
    params?:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
    body?: any;
  } {
    // todo extend options
    return {
      observe: 'response',
      headers: this.getHeader(options),
      params: this.getParams(options),
      withCredentials: true,
      ...(options.body && { body: options.body }),
    };
  }

  private getHeader(options?: any): HttpHeaders {
    const headers = {
      'X-XSRF-Token': sessionStorage.getItem('rvt'),
      'Expose-XSRF-Token': 'true',
      'Content-Type': 'application/json',
      ...(options.headers || {}),
    };
    Object.keys(headers).forEach((key) => {
      if (headers[key] == null) {
        delete headers[key];
      }
    });
    return new HttpHeaders(headers);
  }

  private getParams(options?: any): HttpParams {
    return options && Object.prototype.hasOwnProperty.call(options, 'params')
      ? options.params instanceof HttpParams
        ? options.params
        : this.buildParameters(options.params)
      : null;
  }
}
