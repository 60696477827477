import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { IToast } from '@coyards/shared/models';
import { TranslationPipe } from '@coyards/shared/pipes';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { UiTextComponent } from '../ui-text/ui-text.component';

@Component({
  selector: 'ui-toast',
  standalone: true,
  imports: [
    CommonModule,
    LazyLoadImageModule,
    UiTextComponent,
    TranslationPipe,
  ],
  templateUrl: './ui-toast.component.html',
  styleUrls: ['./ui-toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiToastComponent implements OnInit, OnDestroy {
  @Input() toast: IToast | null;
  @Input() closeIcon = 'toast_close';

  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  ICON_BASE_URL = 'assets/icons/btn/';
  ICON_SHARED_URL = 'assets/icons/shared/';

  ngOnInit(): void {
    this.watchDataChanges();
  }

  private watchDataChanges() {
    this.autoClose();
  }

  private autoClose() {
    setTimeout(() => {
      this.onClose();
    }, 4500);
  }

  onClose(): void {
    this.closeEvent.emit(true);
  }

  ngOnDestroy(): void {
    this.onClose();
  }
}
