import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  /**
   *  storageType - arguments can be 'localStorage' or 'sessionStorage',
   *  key - the name of the item, which is already saved or being saved.
   *  data - data, being saved.
   */

  private storageBrowserSupport(type: string): Storage {
    const storageType = type === 'localStorage' ? localStorage : sessionStorage;
    return typeof storageType !== 'undefined' ? storageType : null;
  }

  public setItem(storageType: string, key: string, data: any): void {
    const storage = this.storageBrowserSupport(storageType);
    !!storage && !!data && storage.setItem(key, JSON.stringify(data));
  }

  public getItem(storageType: string, key: string): any {
    const storage = this.storageBrowserSupport(storageType);
    const item = storage ? storage.getItem(key) : null;
    return JSON.parse(item);
  }

  public removeItem(storageType: string, key: string): void {
    const storage = this.storageBrowserSupport(storageType);
    !!storage && storage.removeItem(key);
  }

  public clear(storageType: string): void {
    const storage = this.storageBrowserSupport(storageType);
    !!storage && storage.clear();
  }
}
