import {
  AccountInfo,
  AlertMessage,
  IBreadcrumb,
  IToast,
} from '@coyards/shared/models';

export interface MainState {
  loggedUser: AccountInfo;
  alertMessage: AlertMessage;
  toast: IToast;
  breadcrumb: IBreadcrumb;
}

export const initMainState: MainState = {
  loggedUser: null,
  alertMessage: null,
  toast: null,
  breadcrumb: null,
};
