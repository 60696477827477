import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';
import { environment } from '@coyards/shared/environments';
import { ActionColors, BUTTON_TYPE, IDropOption } from '@coyards/shared/models';
import { ReplacePipe, TranslationPipe } from '@coyards/shared/pipes';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { UiDividerComponent } from '../ui-divider/ui-divider.component';
import { UiTextComponent } from '../ui-text/ui-text.component';

@Component({
  selector: 'ui-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    LazyLoadImageModule,
    MatTooltipModule,
    MatButtonModule,
    MatMenuModule,
    UiTextComponent,
    UiDividerComponent,
    TranslationPipe,
    ReplacePipe,
  ],
  templateUrl: './ui-dropdown.component.html',
  styleUrls: ['./ui-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiDropdownComponent implements OnChanges, OnInit {
  @Input() classList: string | string[]; // 'btn', 'btn-xs', 'btn-s', 'btn-m', 'btn-l', 'btn-xl', 'btn-xxl'
  @Input() optionClassList: string | string[]; // 'option', 'option-xs', 'option-s', 'option-m', 'option-l', 'option-xl', 'option-xxl'
  @Input() btnType: string; // 'raised', 'flat', 'stroked'
  @Input() btnColor: string; // 'primary', 'accent', 'warn', ''
  @Input() btnCustomColor: string;
  @Input() btnText: string;
  @Input() btnIcon: string;
  @Input() btnImage: string;
  @Input() btnImageURL: string;
  @Input() btnAriaLabel: string;
  @Input() disable: boolean;
  @Input() disableOptionIds: number[] = [];
  @Input() menuOptions: any[];
  @Input() optionBindData: any = null; // is necessary when disable property has a arrow function
  @Input() iconTooltipText: string;
  @Input() translateContext: string;
  @Input() iconTooltipPosition: TooltipPosition = 'below';
  @Input() showAdminPanelVersion = false;

  @Output() clickEvent = new EventEmitter<any>();

  BUTTON_TYPE = BUTTON_TYPE;
  SERVER_URL = environment.s3Url;
  ICON_BASE_URL = 'assets/icons/btn/';
  ICON_SHARED_URL = 'assets/icons/shared/';
  IMG_BASE_URL = 'assets/images/';
  actionColors = ActionColors;

  visibleOptions: IDropOption[] = [];
  isMenuOpened = false;

  ngOnChanges(changes: SimpleChanges): void {
    if ('menuOptions' in changes) {
      this.visibleOptions = this.menuOptions;
    }
  }

  ngOnInit(): void {
    this.sortVisibleOptions();
  }

  private sortVisibleOptions() {
    this.visibleOptions.sort((a, b) => a?.sortOrder - b?.sortOrder);
  }

  emitClick(option) {
    this.clickEvent.emit(option);
  }
}
