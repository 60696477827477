import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router) {}

  canActivate() {
    return this.check();
  }

  canActivateChild() {
    return this.check();
  }

  private check() {
    if (!sessionStorage.getItem('currentUser')) {
      this.router.navigate(['/login']);
    } else {
      return true;
    }

    return false;
  }
}
