<span>
  <span
    class="field"
    [ngClass]="[iconName ? 'field--with-icon' : '']"
    [style.color]="customColor"
    [style.background-color]="customBgColor"
  >
    <label class="field__label" [ngClass]="[value ? 'floated' : '']">
      <ui-text
        [classList]="['placeholder-m', 'placeholder-height-s']"
        [text]="labelText"
      >
      </ui-text>
    </label>

    <span
      *ngIf="iconName"
      class="field__icon field__icon--left"
      [ngClass]="{ disable: disable }"
    >
      <img
        width="20px"
        height="20px"
        [defaultImage]="ICON_SHARED_URL + 'default.svg'"
        [lazyLoad]="ICON_BASE_URL + iconName + '.svg'"
        alt="Input icon"
      />
    </span>

    <input
      matInput
      [type]="isTextMode ? 'text' : 'password'"
      class="field__input"
      [ngClass]="classList"
      [ngClass]="{ disable: disable }"
      [formControl]="passwordFormControl"
      [placeholder]="placeholder"
      [value]="value"
      (keyup)="keyUp()"
      (keydown)="keyDown($event)"
    />

    <button
      class="field__icon field__icon--right"
      type="button"
      [ngClass]="{ disable: disable }"
      (click)="toggleText()"
    >
      <img
        width="24px"
        height="24px"
        [defaultImage]="ICON_SHARED_URL + 'default.svg'"
        [lazyLoad]="
          ICON_BASE_URL + (isTextMode ? 'eye-closed.svg' : 'password-eye.svg')
        "
        alt="Password eye icon"
      />
    </button>
  </span>

  <mat-error *ngIf="hasError && errorText" class="field__error">
    <ui-text
      [classList]="['placeholder-m', 'placeholder-height-s']"
      [text]="errorText"
    >
    </ui-text>
  </mat-error>
</span>
