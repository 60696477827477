export interface ILanguage {
  name: string;
  icon: string;
  locale: string;
  iso2: string;
}
export interface ILanguageText {
  Language: string;
  Name: string;
  ShortName?: string;
}

export interface ILanguageSelect {
  id: number;
  Name: string;
  Language: string;
  icon?: string;
}
