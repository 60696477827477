// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  projectName: 'CoYards',
  styledProjectName: 'Co<b>Yards</b>',
  version: 'v2.1',
  mainUrl: 'https://dev-admin.coyards.app/',
  apiUrl: 'https://dev-admin.coyards.app/api/',
  translationsUrl:
    'https://translation-tool.cosafe.com/Coyards/texts_export_admin.php',
  s3Url: 'https://dev-admin.coyards.app/api/media/file/',
  gmapKey: 'AIzaSyAo2Ghc6AWKdb_H3mNsI5DX76cdcP8Xzlk',
  languages: [
    { name: 'Swedish', locale: 'se', iso2: 'se', icon: 'se.svg' },
    { name: 'English', locale: 'en', iso2: 'en', icon: 'en.svg' },
  ],
  defaultLanguage: 'en',
  initialLatitude: 59.333687,
  initialLongitude: 18.090075,
  timezone: '+01:00',
  defaultPhoneCode: '+46',
  defaultPhoneLength: 9,
  maxIdleTime: 15, // in minutes
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
