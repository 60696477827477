import {
  AccountInfo,
  AlertMessage,
  IBreadcrumb,
  IToast,
} from '@coyards/shared/models';
import { createAction, props } from '@ngrx/store';

// Actions related logged user
export const setLoggedUser = createAction(
  '[Header] Main Module Set Logged User',
  props<{ loggedUser: AccountInfo }>()
);

// Actions related messages
export const setFetchAlertMessage = createAction(
  '[Main Page] Main Module Set Fetch AlertMessage',
  props<{ data: AlertMessage }>()
);

// Actions related messages
export const setToast = createAction(
  '[Main Page] Main Module Set Toast',
  props<{ data: IToast }>()
);

// Actions related breadcrumbs
export const setBreadcrumb = createAction(
  '[Main Page] Main Module Set Breadcrumb',
  props<{ breadcrumb: IBreadcrumb }>()
);

export const logoutAction = createAction('[Main Page] Logout');
