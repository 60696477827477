<div class="radio-btn">
  <mat-radio-button
    [class]="radioButtonClass"
    [name]="name"
    [color]="'accent'"
    [disabled]="disable"
    [value]="value"
    [checked]="check"
  >
    <span [class]="radioButtonContentClassList">
      <ng-container>
        <ng-container *ngIf="labelData">
          <span class="radio-btn__label">
            {{ labelData }}
          </span>
        </ng-container>
        <ng-container *ngIf="!labelData && label">
          <ui-text
            class="radio-btn__label"
            [classList]="['placeholder-m', 'placeholder-height-s']"
            [text]="label"
          >
          </ui-text>
        </ng-container>

        <ng-container *ngIf="subText">
          <ui-text
            [class]="radioButtonSubTextClassList"
            [classList]="['placeholder-m', 'placeholder-height-s']"
            [text]="subText"
          >
          </ui-text>
        </ng-container>

        <ui-tooltip-icon
          *ngIf="iconName"
          [classList]="tooltipIconClassList"
          [iconName]="iconName"
          [iconTooltipText]="iconTooltipText"
        >
        </ui-tooltip-icon>
      </ng-container>
    </span>
  </mat-radio-button>
</div>
