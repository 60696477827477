import { AccountInfo } from './accountInfo';

export interface IMenuItems {
  id: number;
  icon: string;
  label: string;
  route: string;
  order: number;
  active(route: string): boolean;
  show(user: AccountInfo): boolean;
}
