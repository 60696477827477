<div class="activities__container flex j-space-between">
  <ui-preset-title
    [title]="title | translation : translateContext"
    [titleClassList]="titleClassList"
  ></ui-preset-title>
  <div class="activities__box">
    <div
      class="activities__item flex j-space-between a-center f-1"
      *ngFor="let activityItem of activities; last as isLast"
    >
      <div class="item-info flex a-center">
        <div class="icon-container flex j-center a-center">
          <img
            width="24px"
            height="24px"
            [defaultImage]="ICON_SHARED_URL + 'default.svg'"
            [lazyLoad]="ICON_ACTIVITIES_URL + activityItem.icon + '.svg'"
            alt="activities icon"
          />
        </div>

        <ui-preset-title
          [title]="activityItem.score | number : '1.0-0' + ''"
          [subtitle]="activityItem.title | translation : translateContext"
          [titleClassList]="infoTitleClassList"
          [subtitleClassList]="infoSubtitleClassList"
        ></ui-preset-title>
      </div>

      <ui-divider
        *ngIf="!isLast"
        class="divider"
        [horizontal]="true"
        [height]="48"
      >
      </ui-divider>
    </div>
  </div>
</div>
