import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@coyards/shared/environments';
import { AccountInfo, ILanguage } from '@coyards/shared/models';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';

@Injectable()
export class TranslationsService implements OnDestroy {
  private ngUnsubscribe = new Subject();
  langData: unknown = null;

  private availableLanguages: ILanguage[] =
    environment.languages as ILanguage[];
  defaultLang = environment.defaultLanguage;

  language = new BehaviorSubject<ILanguage>(null);
  currentLang = this.language.asObservable();

  constructor(private http: HttpClient) {
    this.loadTexts();
  }

  private fetchTranslations(): Observable<unknown> {
    return this.http.get<unknown>(`${environment.translationsUrl}`);
  }

  private getLanguage(locale: string) {
    return this.availableLanguages.find((lang) => lang.locale === locale);
  }

  private loadTexts() {
    const savedLanguage = JSON.parse(localStorage.getItem('language'));
    const lang = savedLanguage ? savedLanguage.locale : 'en';

    if (!this.langData) {
      this.fetchTranslations()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          if (response) {
            this.langData = response;
            this.changeLanguage(this.getLanguage(lang));
          }
        });
    }
  }

  getDefaultLanguage() {
    return this.getLanguage(this.defaultLang);
  }

  getCurrentLanguage() {
    return this.language.getValue();
  }

  getUserPreferedLanguage(user: AccountInfo) {
    return this.getLanguage(user?.preferredLanguage);
  }

  getAvailableLanguages() {
    return this.availableLanguages;
  }

  changeLangaugeByLocale(locale: string) {
    const lang = this.getLanguage(locale);
    this.changeLanguage(lang);
  }

  changeLanguage(val: ILanguage) {
    // TODO store selected language in main state

    if (!this.availableLanguages.some((lang) => lang.locale === val.locale)) {
      console.log('Language is not available');
    }

    this.language.next(val);
  }

  translate(
    value: string,
    view: string,
    lang: ILanguage = this.getCurrentLanguage(),
    params: { [key: string]: string } = {}
  ): string {
    let text = value;
    const language = lang ? lang : this.getDefaultLanguage();

    if (this.langData) {
      if (
        !this.langData[view] ||
        !this.langData[view][value] ||
        !this.langData[view][value][language.locale]
      ) {
        if (lang != null && Object.keys(this.langData).length > 0) {
          console.log(
            `No Translations for "${view}" with text "${value}" and lang "${language.locale}"`
          );
        }
        text = value;
      } else {
        text = this.langData[view][value][language.locale];
      }
    }

    Object.keys(params).forEach((key) => {
      text = text.replace(`{${key}}`, params[key]);
    });

    return text && text !== value ? text : '...';
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
