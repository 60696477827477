export interface IPermission {
  id: number;
  name: string;
  description: string;
  code?: number | null;
}

export enum PermissionTypes {
  ManageOrg = 'ManageOrg',
  ManageGroup = 'ManageGroup',
  ManageUsers = 'ManageUsers',
  SubOrg = 'SubOrg',
  EditEmergency = 'EditEmergency',
  ManageMenu = 'ManageMenu',
  EmergencyReports = 'EmergencyReports',
  AdminOrg = 'AdminOrg',
  AdminSuborg = 'AdminSuborg',
  AdminGroups = 'AdminGroups',
  AdminUsers = 'AdminUsers',
  ManageUsersExtended = 'ManageUsersExtended',
  ManageUserRole = 'ManageUserRole',
  Notifications = 'Notifications',
  ChangeGroupType = 'ChangeGroupType',
  ManageContent = 'DocumentsManager',
  ManageChecklists = 'ViewOrgChecklist',
  ExternalContactsAdmin = 'ExternalContactsAdmin',
  CoAlert = 'CoAlert',
  WebAdminDocumentsManager = 'WebAdminDocumentsManager',
  WebAdminChecklistManager = 'WebAdminChecklistManager',
  UsersLogOut = 'UsersLogOut',
}
