<div class="phoneNumber">
  <div class="phoneNumber__code">
    <ui-select
      [inputValue]="codeModelId"
      [labelText]="codeLabelText"
      [classList]="['select', 'select-left', 'select-full']"
      [optionClassList]="['option', 'option-left', 'option-full']"
      [panelOptions]="phoneCodes"
      [isMultiple]="false"
      [isFloatLabel]="true"
      (clickEvent)="onChangeCode($event)"
    >
    </ui-select>
  </div>
  <div class="phoneNumber__number">
    <ui-field-text
      [formControl]="numberControl"
      [inputValue]="numberModel"
      [labelText]="numberLabelText"
      [maxlength]="15"
      [hasError]="hasError"
      [errorText]="errorText"
      (input)="onNumberChanged($any($event).target.value)"
      (enterEvent)="enterEvent.emit()"
    >
    </ui-field-text>
  </div>
</div>
