export interface IRole {
  id: number;
  label: string | null;
  name: string;
  Assigned: string;
  publicRole: boolean;
  roleExplained?: string;
}

export enum RoleTypes {
  SUPER_ADMIN = 'SuperAdmin',
  BROADCAST = 'Broadcast',
  JUNIOR_SUPER_ADMIN = 'JuniorSuperAdmin',
  GROUP_ADMIN = 'EditGroups',
}
