import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IGroupMemberUpdateData,
  IUser,
  IUserUpdateData,
  IUsersRequestFilter,
  IUsersResponse,
} from '@coyards/shared/models';
import { ResponseResult } from '@coyards/shared/utilities';
import { Observable } from 'rxjs';
import { RequestHelperService } from './request-helper.service';
import { ResponseHandlerService } from './response-handler.service';

@Injectable({
  providedIn: 'root',
})
export class GeneralApiService {
  constructor(
    private httpClient: HttpClient,
    private requestHelperService: RequestHelperService,
    private responseHandlerService: ResponseHandlerService
  ) {}

  fetchUsers(
    usersFetchFilter: IUsersRequestFilter = null
  ): Observable<ResponseResult<IUsersResponse>> {
    const url = this.requestHelperService.getUrl(`Admin/Users/accounts`);
    const params = { ...usersFetchFilter };
    return this.responseHandlerService.defaultResponseProcessing<IUsersResponse>(
      this.httpClient.get<IUsersResponse>(
        url,
        this.requestHelperService.getOptions({ params })
      )
    );
  }

  editGroupMember(
    groupID: number,
    userID: number,
    groupMemberUpdateData: IGroupMemberUpdateData
  ): Observable<ResponseResult<unknown>> {
    return this.responseHandlerService.defaultResponseProcessing<unknown>(
      this.httpClient.post<unknown>(
        this.requestHelperService.getUrl(
          `Admin/Groups/${groupID}/members/${userID}`
        ),
        groupMemberUpdateData,
        this.requestHelperService.getOptions({})
      )
    );
  }

  updateUserData(
    userID: number,
    userUpdateData: IUserUpdateData
  ): Observable<ResponseResult<IUser | { statusCode: number }>> {
    return this.responseHandlerService.defaultResponseProcessing<IUser>(
      this.httpClient.post<IUser>(
        this.requestHelperService.getUrl(`Admin/Users/${userID}/edit`),
        userUpdateData,
        this.requestHelperService.getOptions({})
      )
    );
  }
}
