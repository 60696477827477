import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { BUTTON_TYPE } from '@coyards/shared/models';
import { TranslationPipe } from '@coyards/shared/pipes';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { UiTextComponent } from '../ui-text/ui-text.component';

@Component({
  selector: 'ui-button',
  standalone: true,
  imports: [
    CommonModule,
    LazyLoadImageModule,
    MatTooltipModule,
    MatButtonModule,
    UiTextComponent,
    TranslationPipe,
  ],
  templateUrl: './ui-button.component.html',
  styleUrls: ['./ui-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiButtonComponent {
  BUTTON_TYPE = BUTTON_TYPE;
  ICON_BASE_URL = 'assets/icons/btn/';
  ICON_SHARED_URL = 'assets/icons/shared/';

  @Input() classList: string | string[]; // 'btn-xs', 'btn-s', 'btn-m', 'btn-l', 'btn-xl', 'btn-xxl', 'btn-border-2px'
  @Input() btnType: string; // 'raised', 'flat', 'stroked'
  @Input() btnColor: string; // 'primary', 'accent', 'warn', ''
  @Input() btnCustomColor: string;
  @Input() btnText: string;
  @Input() btnTextId: string;
  @Input() btnTextColor: string;
  @Input() btnIcon: string;
  @Input() btnAriaLabel: string;
  @Input() routeLink: string;
  @Input() roleType = 'button'; // button, submit, reset, clear
  @Input() disable: boolean;
  @Input() tooltipText: string;
  @Input() tooltipPosition: TooltipPosition = 'below';
  @Input() translateContext: string;

  @Output() clickEvent = new EventEmitter();

  constructor(private router: Router) {}

  private onRoute(route: string) {
    this.router.navigate([route]);
  }

  onClickButton(event) {
    this.clickEvent.emit(event);
    if (this.routeLink) {
      this.onRoute(this.routeLink);
    }
  }
}
