import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IActionType, IDetails, IDropOption } from '@coyards/shared/models';
import { TranslationPipe, TruncatePipe } from '@coyards/shared/pipes';
import { getAdditionalInfoTypeTextId } from '@coyards/shared/utilities';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { UiButtonComponent } from '../ui-button/ui-button.component';
import { UiDropdownComponent } from '../ui-dropdown/ui-dropdown.component';
import { UiPresetTitleComponent } from '../ui-preset-title/ui-preset-title.component';
import { UiTextComponent } from '../ui-text/ui-text.component';

@Component({
  selector: 'ui-details-card',
  standalone: true,
  imports: [
    CommonModule,
    LazyLoadImageModule,
    UiPresetTitleComponent,
    UiButtonComponent,
    UiDropdownComponent,
    TranslationPipe,
    UiTextComponent,
    MatTooltipModule,
    TruncatePipe,
    TranslationPipe,
  ],
  templateUrl: './ui-details-card.component.html',
  styleUrls: ['./ui-details-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiDetailsCardComponent implements OnChanges {
  @Input() data: IDetails;
  @Input() membersTitle: string;
  @Input() locationTitle: string;
  @Input() infoTitle: string;
  @Input() nameClassList: string[] = ['text-xxl', 'text-margin-6']; // optional
  @Input() descriptionClassList: string[] = ['text-xxs'];
  @Input() infoTitleClassList: string[] = [
    'text-s',
    'text-color-grey-400',
    'text-weight-500',
    'text-spacing-l',
    'text-uppercase',
  ];
  @Input() infoSubTitleClassList: string[] = [
    'text-xxs',
    'text-color-grey-700',
    'text-weight-700',
  ];
  @Input() registrationDateClassList: string[] = [
    'text-xxs',
    'text-margin-0',
    'text-color-grey-400',
  ]; // optional
  @Input() cardActions: IDropOption[];
  @Input() translationContext: string;

  @Output() actionEvent = new EventEmitter<IActionType>();
  @Output() titleClickEvent = new EventEmitter();

  ICON_BASE_URL = 'assets/icons/card/';
  ICON_SHARED_URL = 'assets/icons/shared/';

  additionalInfoTypeTextId: string;

  ngOnChanges(changes: SimpleChanges): void {
    if ('data' in changes) {
      this.additionalInfoTypeTextId = getAdditionalInfoTypeTextId(
        this.data?.additionalInfoType
      );
    }
  }

  onClickAction(type: IActionType) {
    this.actionEvent.emit(type);
  }
}
