export interface ILoginForm {
  email: string;
  password: string;
  code: string;
  longitude?: number;
  latitude?: number;
}

export interface IResetPasswordPayload {
  password: string;
  email: string;
  resetCode?: string;
  token?: string;
  userID?: number;
}
