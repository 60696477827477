<div class="title-preset">
  <div class="text">
    <ng-container *ngIf="title">
      <h1 class="text__title" [ngClass]="titleClassList">
        <ui-text
          style="word-wrap: break-word"
          [classList]="['placeholder-m', 'placeholder-height-s']"
          [text]="title"
        >
        </ui-text>
        <ng-content select="[rightAction]"></ng-content>
      </h1>
    </ng-container>
    <ng-container *ngIf="subtitle">
      <h3 class="text__subtitle" [ngClass]="subtitleClassList">
        <ui-text
          [classList]="['placeholder-m', 'placeholder-height-s']"
          [text]="subtitle"
        >
        </ui-text>
      </h3>
    </ng-container>
    <ng-container *ngIf="label">
      <h3 class="text__label" [ngClass]="labelClassList">
        <ui-text
          [classList]="['placeholder-m', 'placeholder-height-s']"
          [text]="label"
        >
        </ui-text>
        <ng-content select="[postLabelText]"></ng-content>
        <ui-tooltip-icon
          *ngIf="tooltipText"
          [classList]="['icon', 'icon-xxxm']"
          [iconName]="tooltipIcon"
          [iconTooltipText]="tooltipText"
        >
        </ui-tooltip-icon>
      </h3>
    </ng-container>
    <ng-container *ngIf="para">
      <p class="text__para" [ngClass]="paraClassList">
        <ui-text
          [classList]="['placeholder-m', 'placeholder-height-s']"
          [text]="para"
        >
        </ui-text>
      </p>
    </ng-container>
  </div>
</div>
