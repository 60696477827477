import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'ui-text',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ui-text.component.html',
  styleUrls: ['./ui-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiTextComponent implements OnChanges {
  @Input() text: string;
  @Input() innerHtml: string;
  @Input() placeholderLines: number;
  @Input() classList: string[] = ['placeholder-m', 'placeholder-height-s'];

  expectedLines: number[] = [1];

  ngOnChanges(changes: SimpleChanges): void {
    if ('placeholderLines' in changes) {
      for (let i = 1; i < this.placeholderLines; i++) {
        this.expectedLines.push(i);
      }
    }
  }
}
