import { IFileTypes } from '../../../models/src/download';

export function downloadFile(
  data: Blob,
  name: string = 'download',
  fileType: IFileTypes = IFileTypes.PDF
) {
  const blob = new Blob([data], { type: `text/${fileType}` });
  const fileName = name + '.' + fileType;
  // Find a fix IE browsers
  const link = document.createElement('a');
  if (link.download !== undefined) {
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
